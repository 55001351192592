import React, { useState } from 'react';
import UserImage from "../assets/svg/user.svg";
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import Icons from "./Icons";
import * as yup from "yup";
import { useFormik } from 'formik';
import { makeRequest } from '../lib/utils';
import { Loader } from '../lib/constants';

const validationSchema = yup.object({
    firstName: yup.string().required("שדה חובה."),
    lastName: yup.string().required("שדה חובה."),
    username: yup.string().required("שדה חובה."),
    password: yup.string().required("שדה חובה."),
    confirmPassword: yup.string().test({
        name: 'confirmPassword',
        exclusive: false,
        params: {},
        message: 'סיסמה לא מתאימה',
        test: (value, context) => value === context.parent.password
    }).required("שדה חובה."),
})

const RegistrationForm = ({ getFormToDisplay }) => {
    const [error, setError] = useState();
    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            username: "",
            password: "",
            confirmPassword: "",
        },

        validationSchema,

        onSubmit: async ({ firstName, lastName, username, password }) => {
            const res = await makeRequest("signup", "POST", { firstName, lastName, username, password });

            if (res.status < 300) {
                getFormToDisplay("login");
            }
            else if (res.status === 400) {
                const data = await res.json();
                if ("DuplicateUserName" in data) {
                    setError("משתמש עם האימייל כבר קיים");
                }
            }
        }
    })
    return (
        <div className='w-full'>
            <header className='p-3 flex flex-col gap-y-3 pb-7 bg-grey-400 border-b border-app-border'>
                {/* Back Button */}
                <Button 
                    type="button" 
                    className="text-sm md:p-0 lg:p-0"
                    onClick={() => getFormToDisplay("login")}
                >
                    <Icons.ArrowLeft className='w-4 h-4' />
                    חזור
                </Button>

                {/* Header Content */}
                <div className='flex flex-col items-center justify-center gap-y-1.5'>
                    <img 
                        src={UserImage}
                        alt="user auth"
                        loading="lazy"
                        className='h-16'
                    />
                    <h6 className='text-sm text-app-text'>רישום האתר</h6>
                </div>
            </header>
            {
                error && <div className='flex justify-center mt-2 text-sm text-red-600'>
                    <p>{error}</p>
                </div>
            }
            {/* Form */}
            <form className='px-4 py-6 grid gap-y-3.5 direction-rtl'>
                <Input 
                    type="name"
                    name="firstName"
                    placeholder="* שם פרטי"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && formik.errors.firstName}
                />

                <Input 
                    type="name"
                    name="lastName"
                    placeholder="* שם משפחה"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && formik.errors.lastName}
                />

                <Input 
                    type="email"
                    name="username"
                    placeholder="* מייל"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={formik.touched.username && formik.errors.username}
                />

                <Input 
                    type="password"
                    name="password"
                    placeholder="* סיסמה"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && formik.errors.password}
                />

                <Input 
                    type="password"
                    name="confirmPassword"
                    placeholder="* אימות סיסמה"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />
                
                {/* Updated */}
                <label htmlFor='updates' className='flex items-center gap-x-1 text-app-text select-none'>
                    <Input 
                        type="checkbox"  
                        name="updates"
                        id="updates"
                        value={true}
                    />
                    הצטרפו לעדכונים שלנו
                </label>

                {
                    formik.isSubmitting ? (
                        <div className='flex justify-center'>
                            <img src={Loader} alt="loading" />
                        </div>
                    ) :
                        (<Button
                            variant="primary"
                            title="form submit button"
                            className="rounded-full"
                            width="full"
                            type="button"
                            onClick={() => {
                                if (error) {
                                    setError(null);
                                }
                                formik.handleSubmit();
                            }}
                        >
                            הרשמה
                        </Button>)
                }
            </form>
        </div>
    )
}

export default RegistrationForm