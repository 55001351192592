import { useRecoilCallback } from "recoil"
import { allProductsFamily } from "../recoil";


export const useAddProducts = () => {

	const addProducts = useRecoilCallback(
		({ set, snapshot }) => async (products) => {

			products.forEach(async (product) => {
				const p = await snapshot.getPromise(allProductsFamily(product.slug));

				if (!p) {
					set(allProductsFamily(product.slug), product)
				}
			});
		}
	);

	return addProducts;
}

