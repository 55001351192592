import React, { useEffect } from 'react';
import { Button } from './ui/Button';
import { useRecoilState } from 'recoil';
import { singleCartItemModalOpen } from '../recoil';
import { Link, useLocation } from 'react-router-dom';

const CartSingleItemCheckoutTray = () => {
	const [modalOpen, setModalOpen] = useRecoilState(singleCartItemModalOpen); //product
	const location = useLocation();

	useEffect(() => {
		if (modalOpen) {
			setModalOpen(false);
		}
	}, [location]);

	if (!modalOpen) {
		return null;
	}

	return (
		<div className='w-screen flex items-center justify-center border-t'>
			<div className="w-[80%] h-fit py-4 min-h-[135px] z-40 border-b border-[#d6d6d5] bg-white pt-2">
				<div className='px-2 flex justify-between flex-col items-center gap-4'>
					<h2 className='font-bold md:text-2xl'>{modalOpen.name}</h2>
					<span className='md:text-2xl text-gray-600'>{`₪ ${modalOpen.price.toLocaleString('en-US')}`}</span>
					<Link
						to="/onestepcheckout">
						<Button
							variant="primary"
							size="none"
							className="w-fit min-w-[auto] px-3 py-2 rounded-full text-xs">
							המשך לתשלום
						</Button>
					</Link>
				</div>
			</div>
		</div>

	)
}

export default CartSingleItemCheckoutTray;