import PlaceIcon from '@mui/icons-material/Place';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { makeRequest } from '../utils';
import Loader from '../components/Loader';
import { Button } from '@mui/material';


export default function BranchDetailPage() {
	const { productId } = useParams();
	const [curBranch, setCurBranch] = useState(null)
	const [fetching, setFetching] = useState(true);
	const { title, address, openingHours, images, phoneNumber } = curBranch ? curBranch : {};
	const navigate = useNavigate()

	const handleEditClicked = () => {
		navigate(`/admin/branch/edit/${productId}`)
	}

	const getProduct = useCallback(async () => {
		const res = await makeRequest(`branch/${productId}`, "GET");

		if (res?.status < 300) {
			setCurBranch(await res.json());
		}
		setFetching(false);
	}, [])

	useEffect(() => {
		getProduct();
	}, [])

	return fetching ?
		(<Loader />)
		:
		curBranch ?
			(
				<div className='flex flex-col gap-2'>
					<div className='flex items-center justify-between'>
						<h2 className="text-4xl font-bold mb-6 flex justify-between">{title} </h2><span><Button size='small' onClick={handleEditClicked}>Edit</Button></span>
					</div>
					<p className='flex gap-2 items-center'><PlaceIcon fontSize='small' /> <span>{address}</span></p>
					<p className='flex gap-2 items-center'><AccessTimeIcon fontSize='small' /><p dangerouslySetInnerHTML={{
						__html: openingHours.replace("\n", "<br />")
					}}></p></p>
					<p className='flex gap-2 items-center'><PhoneIcon fontSize='small' /><span>{phoneNumber}</span></p>
					<div className="grid grid-cols-3 my-6">
						{
							images?.map((url) => (
								<img src={url} alt="" key={url} />
							))
						}
					</div>
				</div>
			) :
			(
				<div className='text-center'><span className='font-bold text-2xl '>There was an error fetching the details of the branch</span></div>
			)
}