
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import * as yup from "yup";
import { useFormik } from "formik";
import { ADMIN_KEY } from "../constants";
import { makeRequest } from "../utils";
import { enqueueSnackbar } from "notistack";
import LoadingScreen from "../components/LoadingScreen";

const validationSchema = yup.object({
	title: yup.string().required("Title is required"),
	address: yup.string().required("Address is required"),
	openingHours: yup.string().required("Opening Hours is required"),
	imageURL: yup.string().required(),
	phoneNumber: yup.string().required("Phone Number is required"),
})


const AddBranchPage = () => {

	const [moreImageURL, setMoreImageURL] = useState({});  //{key:value}
	const [loading, setLoading] = useState(false);
	const currentIndex = useRef(0);
	const navigate = useNavigate();

	const generateKey = () => {
		currentIndex.current += 1;
		return currentIndex.current;
	};

	const handleAddMoreImageUrlField = () => {
		const key = generateKey();
		setMoreImageURL((cur) => ({ ...cur, [key]: "" }))
	}

	const handleRemoveImageUrlField = (key) => {
		const newFields = { ...moreImageURL };
		delete newFields[key];
		setMoreImageURL(newFields);
	}


	const handleImageUrlChange = (key, value) => {
		setMoreImageURL((cur) => ({ ...cur, [key]: value }));
	}


	const formik = useFormik({
		initialValues: {
			title: "",
			address: "",
			imageURL: "",
			phoneNumber: "",
			openingHours: "",
		},

		validationSchema,

		onSubmit: async ({ title, address, imageURL, phoneNumber, openingHours }) => {
			const token = sessionStorage.getItem(ADMIN_KEY);

			const body = {
				title,
				address,
				images: [imageURL, ...Object.values(moreImageURL)],
				phoneNumber,
				openingHours,
			}

			setLoading(true);
			try {
				const response = await makeRequest("branch", "POST", body);

				if (response?.status < 300) {
					enqueueSnackbar({
						message: `New Branch created`, variant: "success"
					})
					navigate(`/admin/branch`, {
						replace: true
					})
				} else {
					enqueueSnackbar({
						message: "An error occurred", variant: "error"
					})
				}
			} catch (error) {
				enqueueSnackbar({
					message: "An error occurred", variant: "error"
				})
			}

			setLoading(false);
		}
	})

	return (
		<div>
			<h2 className="text-4xl font-bold mb-10">New Branch</h2>
			<div className="grid grid-cols-2 gap-6">
				<TextField
					type="text"
					label="Title"
					name="title"
					required
					value={formik.values.title}
					onChange={formik.handleChange}
					error={formik.touched.title && Boolean(formik.errors.title)}
					helperText={formik.touched.title && formik.errors.title}
				/>

				<TextField
					type="text"
					label="Address"
					name="address"
					required
					value={formik.values.address}
					onChange={formik.handleChange}
					error={formik.touched.address && Boolean(formik.errors.address)}
					helperText={formik.touched.address && formik.errors.address}
				/>

				<TextField
					type="text"
					label="Opening Hours"
					name="openingHours"
					required
					multiline
					minRows={3}
					value={formik.values.openingHours}
					onChange={formik.handleChange}
					error={formik.touched.openingHours && Boolean(formik.errors.openingHours)}
					helperText={formik.touched.openingHours && formik.errors.openingHours}
				/>

				<TextField
					type="text"
					label="Phone Number"
					name="phoneNumber"
					required

					value={formik.values.phoneNumber}
					onChange={formik.handleChange}
					error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
					helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
				/>


				<div className="col-span-2 flex flex-col">
					<TextField
						type="text"
						label="Image URL"
						name="imageURL"
						sx={{ width: "50%" }}
						required

						value={formik.values.imageURL}
						onChange={formik.handleChange}
						error={formik.touched.imageURL && Boolean(formik.errors.imageURL)}
						helperText={formik.touched.imageURL && formik.errors.imageURL}
					/>

					<div className="flex flex-col gap-4 mt-4">
						{
							Object.keys(moreImageURL).sort().map((key) => (
								<div className="flex items-center" key={key}>
									<TextField
										type="text"
										label="Image URL"
										name={`imageURL${key}`}
										sx={{ width: "50%" }}
										key={key}
										value={moreImageURL[key]}
										onChange={(evt) => handleImageUrlChange(key, evt.target.value)}
									/>

									<IconButton onClick={() => handleRemoveImageUrlField(key)}>
										< DeleteIcon color="error" />
									</IconButton>
								</div>
							))
						}
					</div>

					<span className="bg-primary-500 text-white rounded-full px-4 py-2 cursor-pointer w-fit mt-2" onClick={handleAddMoreImageUrlField}>Add another image</span>

				</div>

				<div className="col-span-2 flex justify-center mt-4">
					<span className="bg-primary-500 text-white rounded-full px-4 py-2 cursor-pointer w-1/2 mt-2 text-center" onClick={formik.handleSubmit}>SAVE</span>
				</div>
			</div>

			<LoadingScreen open={loading} />
		</div>
	)
}

export default AddBranchPage;