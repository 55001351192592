import React from 'react';
import UserImage from "../assets/svg/user.svg";
import { Button } from './ui/Button';
import { useSetRecoilState } from 'recoil';
import { user } from '../recoil';
import { USER_KEY } from '../lib/constants';
import Icons from './Icons';


const DisconnectForm = ({ getFormToDisplay, closeModal }) => {
	const setUser = useSetRecoilState(user);

	const handleLogOut = () => {
		sessionStorage.removeItem(USER_KEY);
		setUser(null);
		closeModal();
	}

	return (
		<div className='w-full'>
			<header className='px-3 pt-3 flex flex-col gap-y-3 bg-grey-400 border-app-border pb-10'>
				<Button
					type="button"
					className="text-sm md:p-0 lg:p-0"
					onClick={() => getFormToDisplay("account")}
				>
					<Icons.ArrowLeft className='w-4 h-4' />
					חזור
				</Button>
				{/* Header Content */}
				<div className='flex flex-col items-center justify-center gap-y-1.5'>
					<img
						src={UserImage}
						alt="user auth"
						loading="lazy"
						className='h-16'
					/>
					<h6 className='text-sm text-app-text uppercase'>התנתקות מהמערכת</h6>
				</div>
			</header>
			<div className='py-6 px-4 flex flex-col gap-4 direction-rtl justify-center items-center'>
				<p>אתם בטוחים שאתם רוצים להתנתק?</p>
				<Button
					variant="primary"
					title="form submit button"
					className="rounded-full"
					width="full"
					onClick={handleLogOut}
				>
					התנתקות
				</Button>
			</div>
		</div>
	)
}

export default DisconnectForm;