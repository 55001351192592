import Paper from '@mui/material/Paper';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button"
import * as yup from "yup"
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { getFullUrl } from '../utils';
import { ADMIN_KEY } from '../constants';


const validationSchema = yup.object({
	username: yup.string().required("Username cannot be empty"),
	password: yup.string().required("Password cannot be empty")
})

export default function AdminSignIn() {
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			username: "",
			password: ""
		},
		validationSchema,
		onSubmit: async (values) => {
			const res = await fetch(getFullUrl("login"), {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(values)
			}).catch(err => ({ status: 500 }))

			if (res.status !== 200) {
				enqueueSnackbar({
					message: "An error ocurred", variant: "error", autoHideDuration: 3000
				})
				return;
			}
			const { token } = await res.json();
			sessionStorage.setItem(ADMIN_KEY, token);
			navigate("/admin", {
				replace: true
			});
		}
	})
	return (
		<div className='h-screen w-screen flex justify-center items-center'>
			<Paper elevation={3}>
				<div className='flex flex-col p-10 gap-3 w-[90vw] md:w-[50vw]'>
					<h1 className='font-bold text-xl self-center'>ADMINISTRATION</h1>
					<TextField label="Username" type='text' helperText={formik.touched.username && formik.errors.username}
						error={formik.touched.username && Boolean(formik.errors.username)}
						value={formik.values.username}
						onChange={formik.handleChange} name="username"
						autoFocus />
					<TextField label="Password" type='password' name='password'
						helperText={formik.touched.password && formik.errors.password}
						error={formik.touched.password && Boolean(formik.errors.password)}
						value={formik.values.password}
						onChange={formik.handleChange} />

					<Button variant='contained' sx={{
						width: "fit-content", alignSelf: "center", paddingX: "2rem"
					}} onClick={formik.handleSubmit} disabled={formik.isSubmitting}>Login</Button>
				</div>

			</Paper>
		</div>

	)
}