import { Navigate, Outlet, useNavigate } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HandymanIcon from '@mui/icons-material/Handyman';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import WatchIcon from '@mui/icons-material/Watch';
import BusinessIcon from '@mui/icons-material/Business';
import Toolbar from '@mui/material/Toolbar';
import { useCallback, useContext, useEffect, useState } from "react";
import NavItem from "../components/NavItem";
import { ADMIN_KEY, BASE_URL } from "../constants";
import AppLogo from "../../components/AppLogo";
import IStoreLogo from "../../assets/svg/logo.svg";
import { AdminContext } from "../adminContext";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SdStorageIcon from '@mui/icons-material/SdStorage';

const drawerWidth = 240;

export default function AdminLayout(props) {
	const token = sessionStorage.getItem(ADMIN_KEY);
	const navigate = useNavigate()
	const { window } = props;
	const [mobileOpen, setMobileOpen] = useState(false);
	const { productTypes, accessoryCategories } = useContext(AdminContext);

	const handleLogout = () => {
		sessionStorage.removeItem(ADMIN_KEY)
		navigate("/admin/signin", {
			replace: true
		})
	}

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const setups = useCallback(async () => {

		let res = await fetch(`${BASE_URL}producttype`);

		if (res.status < 300) {
			const types = await res.json();
			productTypes.current = types;
		}
	}, [])

	const fetchAccessoriesCategories = useCallback(async () => {
		let res = await fetch(`${BASE_URL}category/get-accessory-categories`);

		if (res.status < 300) {
			const categories = await res.json();
			accessoryCategories.current = categories;
		}
	}, []);

	useEffect(() => {
		setups()
		fetchAccessoriesCategories()
	}, [])

	if (!token) {
		return <Navigate to="signin" replace={true} />;
	}

	const drawer = (
		<div>
			<Toolbar>
				<AppLogo
					src={IStoreLogo}
					href="/"
					className="max-w-[8em]"
				/>
			</Toolbar>
			<Divider />
			<div className="w-full border-r-2 select-none p-3 flex flex-col gap-1">

				<NavItem text="Dashboard" to="" handleClick={handleDrawerToggle}>
					<DashboardIcon fontSize="small" />
				</NavItem>


				<NavItem text="Accessory" to="accessory" handleClick={handleDrawerToggle}>
					<HandymanIcon fontSize="small" />
				</NavItem>


				<NavItem text="IPhone" to="phone" handleClick={handleDrawerToggle}>
					<PhoneIphoneIcon />
				</NavItem>

				<NavItem text="IPad" to="ipad" handleClick={handleDrawerToggle}>
					<TabletAndroidIcon fontSize="small" />
				</NavItem>


				<NavItem text="Mac" to="mac" handleClick={handleDrawerToggle}>
					<LaptopMacIcon fontSize="small" />
				</NavItem>


				<NavItem text="Watch" to="watch" handleClick={handleDrawerToggle}>
					<WatchIcon />
				</NavItem>

				<hr className="my-4 border-y-2" />
				<NavItem text="Storage" to="storage" handleClick={handleDrawerToggle}>
					<SdStorageIcon />
				</NavItem>


				<NavItem text="Color" to="color" handleClick={handleDrawerToggle}>
					<ColorLensIcon />
				</NavItem>
				<NavItem text="Branch" to="branch" handleClick={handleDrawerToggle}>
					<BusinessIcon />
				</NavItem>
				<hr className="my-4 border-y-2" />
				<NavItem text="Order" to="order" handleClick={handleDrawerToggle}>
					<ShoppingCartIcon />
				</NavItem>
			</div>
		</div>
	);

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<Box sx={{ display: 'flex' }}>

			<AppBar
				position="fixed"
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` },
					backgroundColor: "white",
					color: "black"
				}}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: 'none' } }}
					>
						<MenuIcon />
					</IconButton>
					<div className="flex justify-end w-full">
						<span className="rounded-lg cursor-pointer hover:text-white px-4 py-1 hover:bg-red-500 text-red-600 duration-300" onClick={handleLogout}>Logout</span>
					</div>
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
				aria-label="mailbox folders"
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
				>
					{drawer}
				</Drawer>


				<Drawer
					variant="permanent"
					sx={{
						display: { xs: 'none', sm: 'block' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>
			<Box
				component="main"
				sx={{ p: 3, width: { sm: `calc(100% - ${drawerWidth}px)`, height: "100vh", overflowY: "scroll" } }}
			>
				<Toolbar />
				<Outlet />
			</Box>
		</Box>
	);
}