import { createContext, useRef } from "react";


export const AdminContext = createContext({
	productTypes: {},
	accessoryCategories: {},
})

export default function AdminContextProvider({ children }) {
	const productTypes = useRef({});
	const accessoryCategories = useRef(null);

	return (
		<AdminContext.Provider value={{ productTypes, accessoryCategories }}>
			{children}
		</AdminContext.Provider>
	)
}