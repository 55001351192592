import React, { useState } from 'react'
import Icons from '../Icons';
import { Link } from 'react-router-dom';

const IphoneNavigation = () => {
    const [isDropdownActive, setIsDropdownActive] = useState(false);

    return (
        <nav className='direction-rtl bg-white/70 backdrop-blur-[20px] backdrop-saturate-[1.8] transition-all duration-75'>
        <div className='wrapper px-6 py-2.5 lg:py-2'>
            {/* Links */}
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-4">
                    <Icons.AirtagLogo className="h-[21px] lg:h-[20px] mt-[6px]" />

                    {/* Arrow Y Axis */}
                    <span 
                        className={`${isDropdownActive ? "active arrow-y-axis": "arrow-y-axis"} cursor-pointer block lg:hidden`}
                        onClick={() => setIsDropdownActive(props => !props)}
                    />
                </div>

                {/* Links */}
                <div className="flex items-center gap-x-2.5">
                    <span className="opacity-[.56] text-black text-[13px] px-4 py-2 font-light hidden lg:inline-block">סקירה כללית</span>
                    <Link
                        to="/iPhone/apple-airtag"
                        className="text-[13px] bg-primary-400 text-white border border-primary-400 px-4 py-[.6em] lg:py-[.7em] min-w-[8em] rounded-full leading-4 flex items-center justify-center font-light"
                    >
                        לרכישה
                    </Link>
                </div>
            </div>

            {/* Dropdown */}
            {isDropdownActive && (
                <div className="w-full py-[15px] px-5 block lg:hidden">
                    <span className="py-[15px] text-[13px] font-light text-black opacity-[.56] inline-block">סקירה כללית</span>
                </div>
            )}
        </div>
        </nav>
    )
}

export default IphoneNavigation