import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { companyPhoneLine, whatsappLink } from '../../lib/constants';
import useScroll from '../../hooks/useScroll';
import usePath from '../../hooks/usePath';
import AppLogo from '../AppLogo';
import CartIcon from '../CartIcon';
import AARImage from "../../assets/svg/aar.svg";
import IStoreLogo from "../../assets/svg/logo.svg";
import WhatsappLogo from "../../assets/images/social/whatsapp.png";
import PhoneCircleLogo from "../../assets/svg/phone-circle.svg";
import SearchBoxButton from '../SearchBoxButton';
import Hamburger from '../Hamburger';
import LaboratoryNavigation from './LaboratoryNavigation';
import IphoneNavigation from './IphoneNavigation';
import WishlistIcon from '../WishlistIcon';
import CartSingleItemCheckoutTray from '../CartSingleItemCheckoutTray';
import OrderSuccessfulTray from '../OrderSuccessTray';
import UserFormIcon from '../UserFormIcon';

const MobileNavigation = () => {
    const { pathname } = useLocation();
    const { activePath } = usePath("laboratory");
    const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
    const { navigationIsSticky } = useScroll();
    
    return (
        <div className={`w-full ${navigationIsSticky ? "fixed top-0 left-0" : ""} flex flex-col xl:hidden z-50 divide-y divide-app-border`}>
            <nav className="w-full">
                <div className="w-full bg-white shadow-mobileNav flex justify-center transition-all duration-1000">
                    <div className='wrapper px-2 md:px-3 py-1 lg:p-2 flex items-center justify-between'>

                        {/* Actions */}
                        <div className='flex items-center gap-x-2.5 md:gap-x-3.5 md:hidden pl-3'>
                            {/* Cart Items Count */}
                            <CartIcon />

                            {/* Whatsapp contact */}
                            <Link
                                to={`${whatsappLink}`}
                                title="Contact Us On WhatsApp"
                                target='_blank'
                                className='select-none'
                            >
                                <img
                                    src={WhatsappLogo}
                                    alt="Contact us on WhatsApp"
                                    loading='lazy'
                                    className='h-[1.35rem] xs:h-6 sm:h-[1.65rem] md-lg:h-7'
                                />
                            </Link>

                            {/* Company Phone Line */}
                            <Link
                                to={`${companyPhoneLine}`}
                                className='select-none inline-block md:hidden'
                            >
                                <img
                                    src={PhoneCircleLogo}
                                    alt="Company Phone Line"
                                    loading='lazy'
                                    className='h-[1.15rem] xs:h-5 sm:h-[1.4rem]'
                                />
                            </Link>
                            {/* User Auth Form Button */}
                            <UserFormIcon />
                        </div>
                        <AppLogo 
                            src={IStoreLogo}
                            href="/"
                            className= "max-w-[5.2em] xs:max-w-[5.5em] md:max-w-[6em]"
                        />

                        <div className='flex items-center p-2'>
                            <figure className='pr-2 sm:pr-4 py-0.5 w-20 md:w-24 md-lg:py-0 md-lg:w-[6.7rem] border-r border-app-border'>
                                <img 
                                    src={AARImage}
                                    alt="apple Authorized Reseller"
                                    loading='lazy'
                                    className='h-full w-full'
                                />
                            </figure>
                                
                            {/* Hamburger */}
                            <Hamburger className="pl-2 sm:pl-4" />
                        </div>
                    </div>
                </div>
                <OrderSuccessfulTray />
                <CartSingleItemCheckoutTray />
            </nav>

            {/* Laboratory Navigation */}
            {activePath === "/מעבדה" && (
                <LaboratoryNavigation />
            )}

            {pathname.includes("airtag") ? (
                <IphoneNavigation />
            ) : null}
        </div>
    )
}

export default MobileNavigation