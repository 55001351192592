import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { enqueueSnackbar } from "notistack";
import { getFullUrl, makeRequest } from "../utils";
import { ADMIN_KEY } from "../constants";
import DeleteDialog from "../components/dialogs/DeleteDialog";
import AddStorageDialog from "../components/dialogs/AddStorageDialog";

const columns = [
	{ id: 'id', label: 'Storage ID', maxWidth: 80 },
	{ id: 'capicity', label: 'Capacity', minWidth: 80 },
	{ id: 'actions', label: 'Actions' }
]


const StoragePage = () => {

	const [rows, setRows] = useState([

	]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [dialog, setDialog] = useState(null);
	const [newStorage, setNewStorage] = useState(null);
	const [fetching, setFetching] = useState(true);
	const [needRefresh, setNeedRefresh] = useState(false)

	const handleDeleteSuccessful = (id) => {
		if (id) {
			setRows((cur) => cur.filter(c => c.id !== id));
		}
		setDialog(false);
	}


	const getStorages = useCallback(async () => {

		const res = await makeRequest("storage", "GET");

		if (res.status === 200) {
			const storages = await res.json();
			setRows(storages);
		} else {
			enqueueSnackbar({
				message: "An error occurred fetching Orders", variant: "error",
				autoHideDuration: 2000
			})
		}
		setFetching(false);

	}, [needRefresh])

	useEffect(() => {
		getStorages();
	}, [needRefresh])


	const handleAddStorage = (added) => {
		if (added) {
			setNeedRefresh(!needRefresh);
		}

		setNewStorage(false);
	}

	return (
		<>
			<div className="flex justify-between">
				<h2 className="text-4xl font-bold mb-10 uppercase">Storage</h2>
				<span>
					<Button variant="contained" sx={{ backgroundColor: "#014868" }} onClick={() => setNewStorage(true)}>Add new <span className="uppercase ml-2"> storage</span></Button></span>
			</div>
			{
				fetching ? (
					<Loader />
				)
					:
					(<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer sx={{ maxHeight: 440 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth }}
												sx={{ fontWeight: 700 }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={row.orderID}>
													{columns.map((column) => {
														const value = row[column.id];
														return column.id === "actions" ?
															(<TableCell key={column.id}>
																<div className='flex items-center mt-2 gap-3'>
																	<Button variant='contained' color='error' onClick={() => setDialog(row.id)}
																		size="small">Delete</Button></div>
															</TableCell>)
															: (
																<TableCell key={column.id} align={column.align}>
																	{column.format
																		? column.format(value)
																		: value}
																</TableCell>
															);
													})}

												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50, 100]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							labelRowsPerPage="Orders per page:"
							onRowsPerPageChange={(evt) => setRowsPerPage(evt.target.value)}
							onPageChange={(evt, page) => setPage(page)}
						/>
					</Paper>)}

			{
				dialog && <DeleteDialog title="storage" id={dialog} handleClose={handleDeleteSuccessful} />
			}
			{
				newStorage && <AddStorageDialog handleClose={handleAddStorage} />
			}
		</>
	)
}

export default StoragePage;