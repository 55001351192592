import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { makeRequest } from "../utils";
import { enqueueSnackbar } from "notistack";
import LoadingScreen from "../components/LoadingScreen";



const validationSchema = yup.object({
	title: yup.string().required("Title is required"),
	spec: yup.string().required("This field is required"),
	price: yup.number().required("Price is required"),
	storage: yup.string().required("This field is required")
})



const AddModel = () => {
	const { state } = useLocation();
	const { product } = useParams();
	const { productId, productType, name } = state;
	const [storages, setStorages] = useState([]);
	const currentIndex = useRef(0);
	const [specs, setSpecs] = useState({});  //{key:value}
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const generateKey = () => {
		currentIndex.current += 1;
		return currentIndex.current;
	};

	const handleSpecChange = (key, value) => {
		setSpecs((cur) => ({ ...cur, [key]: value }));
	}

	const handleAddMoreSpec = () => {
		const key = generateKey();
		setSpecs((cur) => ({ ...cur, [key]: "" }))
	}

	const handleRemoveSpec = (key) => {
		const newFields = { ...specs };
		delete newFields[key];
		setSpecs(newFields);
	}


	const formik = useFormik({
		initialValues: {
			title: "",
			spec: "",
			price: "",
			storage: -1,
		},
		validationSchema,

		onSubmit: async ({ title, spec, price, storage }) => {
			setLoading(true);
			const payload = {
				productId: +productId,
				productType: +productType,
				title,
				price,
				storageID: storages.filter(s => s.capicity === storage)[0].id,
				specs: [spec, ...Object.values(specs)],
			}
			const res = await makeRequest("productmodel", "POST", payload);

			if (res.status < 300) {
				enqueueSnackbar({
					message: "Model added", variant: "success"
				});
				navigate(`/admin/${product}/detail/${productId}`);
			} else {
				const data = await res.json();
				enqueueSnackbar({
					message: data?.error ? data.error[0] : "An error occurred", variant: "error"
				});
			}
			setLoading(false);
		}
	})

	const getStorages = useCallback(async () => {
		const res = await makeRequest("storage", "GET");

		if (res.status < 300) {
			const storages = await res.json();
			setStorages(storages);
		}
	}, []);

	useEffect(() => {
		getStorages();
	}, []);


	return (
		<div>
			<h2 className="text-4xl font-bold mb-10">{name}</h2>
			<div className="grid grid-cols-2 gap-6">
				<TextField
					type="text"
					label="Title"
					name="title"
					required

					value={formik.values.title}
					onChange={formik.handleChange}
					error={formik.touched.title && Boolean(formik.errors.title)}
					helperText={formik.touched.title && formik.errors.title}
				/>
				<TextField
					type="number"
					label="Price"
					name="price"
					required

					value={formik.values.price}
					onChange={formik.handleChange}
					error={formik.touched.price && Boolean(formik.errors.price)}
					helperText={formik.touched.price && formik.errors.price}
				/>

				<div className="col-span-2">
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={formik.values.storage}
						onChange={formik.handleChange}
						name="storage"
						sx={{ width: "25%" }}
						error={formik.touched.storage && Boolean(formik.errors.storage)}
						helperText={formik.touched.storage && formik.errors.storage}
					>

						<MenuItem value={-1} disabled>Select a storage</MenuItem>
						{
							Object.values(storages).map(({ id, capicity }) => (
								<MenuItem key={capicity} value={capicity}>{capicity}</MenuItem>
							))
						}
					</Select>
				</div>
				<div className="col-span-2 flex flex-col">
					<TextField
						type="text"
						label="Spec"
						name="spec"
						required
						fullWidth
						value={formik.values.spec}
						onChange={formik.handleChange}
						error={formik.touched.spec && Boolean(formik.errors.spec)}
						helperText={formik.touched.spec && formik.errors.spec}
					/>

					<div className="flex flex-col gap-4 mt-4 w-full">
						{
							Object.keys(specs).sort().map((key) => (
								<div className="flex items-center w-full">
									<TextField
										type="text"
										label="Spec"
										name={`spec${key}`}
										key={key}
										value={specs[key]}
										onChange={(evt) => handleSpecChange(key, evt.target.value)}
										fullWidth
									/>

									<IconButton onClick={() => handleRemoveSpec(key)}>
										< DeleteIcon color="error" />
									</IconButton>
								</div>
							))
						}
					</div>

					<span className="bg-primary-500 text-white rounded-full px-4 py-2 cursor-pointer w-fit mt-2" onClick={handleAddMoreSpec}>Add another spec</span>

				</div>
			</div>
			<div className="col-span-2 flex justify-center mt-4">
				<span className="bg-primary-500 text-white rounded-full px-4 py-2 cursor-pointer w-1/2 mt-2 text-center" onClick={formik.handleSubmit}>SAVE</span>
			</div>
			<LoadingScreen open={loading} />
		</div>
	)
}

export default AddModel;