import React, { useEffect, useState } from 'react'
import { Select } from './ui/Input';
import { Button } from "./ui/Button";
import CloseIcon from "../assets/images/close.png";
// import Random from "../assets/images/w_290.webp";
import { Link } from 'react-router-dom';
import { useCart } from '../providers';

const CartBasketItem = ({ id, name, imageUrls, price, quantity }) => {
    const { updateCartItemCount, removeCartItem } = useCart();
    const [cartCount, setCartCount] = useState(0);

    // Set cart item count
    useEffect(() => {
        setCartCount(quantity);
    }, [quantity]);

    return (
        <blockquote id={`cart-${id}`} className='grid grid-cols-cartBasket gap-x-6'>
            {/* Cart Actions */}
            <div className='flex flex-col gap-y-3'>
                <Select 
                    options={[1,2,3,4,5,6,7,8,9,10]}
                    value={cartCount}
                    onChange={({ target }) => {
                        // Set value
                        setCartCount(target.value);

                        // Update cart item count
                        updateCartItemCount(id, parseInt(target.value));
                    }}
                    containerClassName="bg-white"
                />

                {/* Remove Cart Item Button */}
                <Button 
                    size="none"
                    onClick={() => removeCartItem(id)}
                >
                    <img 
                        src={CloseIcon}
                        alt="close cart basket item"
                        className='w-4'
                    />
                </Button>
            </div>

            {/* Cart Item Description */}
            <div className="text-end space-y-1">
                {/* Cart Title */}
                <Link 
                    to="Watch/watch-se-2nd/buy/apple-apple-watch-se-gps-cellular-44mm-midnight-aluminium-case-midnight-sport-band-ml"
                    className="font-sans text-app-text font-light text-md"
                >
                    {name}  
                </Link>
                
                {/* Cart Item Price */}
                <span>₪ {price}</span>
            </div>

            {/* Cart Item Image */}
            <figure className="h-28">
                <img 
                    src={imageUrls[0]}
                    alt="cart title"
                    className='w-full h-full object-cover'
                />
            </figure>
        </blockquote>
    )
}

export default CartBasketItem