import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack';
import * as yup from "yup";
import { useFormik } from 'formik';
import { makeRequest } from '../../utils';

const validationSchema = yup.object({
	capacity: yup.string().required("capacity is required"),
})

export default function AddStorageDialog({ handleClose }) {

	const formik = useFormik({
		validationSchema,
		initialValues: {
			capacity: '',
		},

		onSubmit: async (values) => {

			const res = await makeRequest("storage", "POST", values)

			enqueueSnackbar({
				message: res?.status < 300 ? "Storage added" : "An error occurred", variant: res?.status < 300 ? "success" : "error", autoHideDuration: 2000
			})
			handleClose(res?.status < 300 ? true : false)
		}
	})


	return (
		<>
			<Dialog open={true}>
				<DialogTitle>New Storage</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						label="Capacity"
						name='capacity'
						fullWidth
						variant="standard"
						onChange={formik.handleChange}
						value={formik.values.capacity}
						error={formik.touched.capacity && Boolean(formik.errors.capacity)}
						helperText={formik.touched.capacity && formik.errors.capacity}
					/>

				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose()} disabled={formik.isSubmitting}>Cancel</Button>
					<Button onClick={formik.handleSubmit} disabled={formik.isSubmitting}>Confirm</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
