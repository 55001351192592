import { useCallback, useEffect, useState } from "react";
import { ADMIN_KEY } from "../../constants";
import { getFullUrl, makeRequest } from "../../utils";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { enqueueSnackbar } from "notistack";
import Loader from "../Loader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";


export default function ProductColorDialog({ productId, productType, handleClose }) {

	const [colors, setColors] = useState([]);
	const [fetching, setFetching] = useState(true);
	const [setting, setSetting] = useState(false);
	const [selectedColor, setSelectedColor] = useState(-1)
	const token = sessionStorage.getItem(ADMIN_KEY);

	const getColors = useCallback(async () => {
		const res = await fetch(getFullUrl("color"), {
			headers: {
				Authorization: `Bearer ${token}`
			}
		})

		if (res.status < 300) {
			const c = await res.json();
			setColors(c);
		}

		setFetching(false)
	}, [])

	const handleColorSelected = async () => {
		if (selectedColor === -1) {
			return;
		}
		setSetting(true)

		const res = await makeRequest("productcolor", "POST", {
			productId,
			productType,
			colorId: selectedColor
		});

		if (res.status < 300) {
			enqueueSnackbar({
				message: "Color added to product", variant: "success", autoHideDuration: 3000
			});
			handleClose(colors.filter(c => c.id === selectedColor)[0]);
			return;
		}
		else if (res.status === 400) {
			enqueueSnackbar({
				message: "This product already have this color set", variant: "error", autoHideDuration: 3000
			})
		}
		else {
			enqueueSnackbar({
				message: "An error occurred", variant: "error"
			})
		}
		setSetting(false)
		handleClose();
	}

	useEffect(() => {
		getColors()
	}, [])


	return (
		<>
			<Dialog open={true}>
				<DialogTitle>Add Product Color</DialogTitle>
				<DialogContent>
					<div className="w-[90vw] md:w-[30vw]">
						{
							fetching ? (<Loader />)
								:
								(
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={selectedColor}
										onChange={(evt) => setSelectedColor(evt.target.value)}
										fullWidth
										name="category"
										sx={{ marginLeft: 1 }}

									>
										<MenuItem value={-1} disabled>Select a color</MenuItem>

										{
											colors.map((c) => <MenuItem key={c.id} value={c.id}>
												<div className="flex justify-between w-full">
													<span>{c.name}</span>
													<div className="w-6 h-6 rounded-full" style={{ backgroundColor: c.hex }}>
													</div>
												</div>
											</MenuItem>)
										}
									</Select>

								)
						}
					</div>

				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose()} disabled={setting}>Cancel</Button>
					<Button onClick={handleColorSelected} disabled={setting}>Confirm</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}