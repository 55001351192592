import { useCallback, useEffect, useState } from "react";
import StatItem from "../components/StatItem"
import { ADMIN_KEY } from "../constants";
import { getFullUrl, makeRequest } from "../utils";
import { enqueueSnackbar } from "notistack";
import Loader from "../components/Loader";



const DashBoard = () => {
	const [stats, setStats] = useState({});
	const [loading, setLoading] = useState(false);


	const getProductStats = useCallback(async () => {
		setLoading(true);

		const res = await makeRequest("product-count", "GET");

		if (res?.status === 200) {
			const stats = await res.json();
			setStats(stats);
		} else {
			enqueueSnackbar({
				message: "An error occurred while fetching stat", variant: "error"
			})
		}

		setLoading(false)
	}, [])

	useEffect(() => {
		getProductStats()
	}, []);

	return (
		<>
			<h2 className="text-4xl font-bold mb-10">Dashboard</h2>
			{
				loading ? (<Loader />)
					: (
						<div className="grid grid-cols-3 gap-6">

							{
								Object.entries(stats).map(([k, v]) => <StatItem key={k} title={`Total ${k}`} value={v} />)
							}
						</div>
					)
			}
		</>);

}

export default DashBoard;