import React, { Suspense, useCallback, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import {
  Footer,
  Header,
  Notfound
} from "./components";
import {
  ProductCollectionLayout,
  Contact,
  Accessories,
  Business,
  Ipad,
  IphoneLayout,
  Laboratory,
  MacLayout,
  Mac,
  MacbookPro,
  MacbookProUpgrade,
  TradeIn,
  TV,
  Watch,
  Checkout,
  OneStepCheckout,
  AccessoriesByLayout,
  ClearanceLayout,
  StudentRegister,
  MusicLayout,
  AppleAirpodMaxDescription,
  RefurbishLayout,
  Academy,
  Purchase,
  IPhone11Info,
  Warranty,
  IStoreCare,
  CareersLayout,
  Careers,
  CareersApplication,
  About,
  OrderCancel,
  BuyMe,
  Regulations,
  IStoreCareCheckDevice,
  StudentProgram,
  LaboratorySupport,
  SupportService,
  Iphone,
  Airtag
} from "./pages";
import { useOffscreen } from './providers';
import { AdminApp } from './admin';
import Loader from './admin/components/Loader';

function App() {
  const { setType, openOffscreen } = useOffscreen();
  const subscribeModalCount = sessionStorage.getItem("subscribeCount") ? JSON.parse(sessionStorage.getItem("subscribeCount")) : 0;
  
  const openSubscribeModal = useCallback(() => {
    setType("subscribe-modal");
    openOffscreen();

    // Set storage
    sessionStorage.setItem("subscribeCount", 1);
  }, [subscribeModalCount]);

  useEffect(() => {
    if(subscribeModalCount < 1)
      openSubscribeModal();
  }, [openSubscribeModal, subscribeModalCount]);

  return (
    <main className='font-sans rtl:mr-3'>
      {/* Header */}
      <Header />

      {/* Routes */}  
      <Suspense fallback={
        <div className='py-8'><Loader /></div>
      }>
        <Routes>
          <Route path="/*" element={<ProductCollectionLayout />} />
          <Route path="שירות-לקוחות" element={<Contact />} />
          <Route path="careers" element={<CareersLayout />}>
            <Route index element={<Careers />} />
            <Route path="application/apply/ref/70/" element={<CareersApplication />} />
          </Route>
          <Route path="אביזרים" element={<Accessories />} />
          <Route path="music/*" element={<MusicLayout />} />
          <Route path="לקוחות-עסקיים" element={<Business />} />
          <Route path="ipad" element={<Ipad />} />
          <Route path="refurbish/*" element={<RefurbishLayout />} />
          <Route path="regulations" element={<Regulations />} />
          <Route path="iphone" element={<IphoneLayout />}>
            <Route index element={<Iphone />} />
            <Route path="airtag" element={<Airtag />} />
          </Route>
          <Route path="academy" element={<Academy />} />
          <Route path="about" element={<About />} />
          <Route path="about/אמנת-השירות" element={<About />} />
          <Route path="מעבדה" element={<Laboratory />} />
          <Route path="מעבדה/support" element={<LaboratorySupport />} />
          <Route path="buyme" element={<BuyMe />} />
          <Route path="apple-airpods-max" element={<AppleAirpodMaxDescription />} />
          <Route path="order_cancel" element={<OrderCancel />} />
          <Route path="mac" element={<MacLayout />}>
            <Route index element={<Mac />} />
            <Route path="macbook-pro" element={<MacbookPro />} />
            <Route path="macbook-pro/upgrade" element={<MacbookProUpgrade />} />
          </Route>
          <Route path="trade-in" element={<TradeIn />} />
          <Route path="tv" element={<TV />} />
          <Route path="clearance/*" element={<ClearanceLayout />} />
          <Route path="iPhone-11-Info" element={<IPhone11Info />} />
          <Route path="watch" element={<Watch />} />
          <Route path="istore-care" element={<IStoreCare />} />
          <Route path="istore-care/check-device" element={<IStoreCareCheckDevice />} />
          <Route path="warranty" element={<Warranty />} />
          <Route path="purchase" element={<Purchase />} />
          <Route path="/אביזרים/אביזרים-לפי/*" element={<AccessoriesByLayout />} />
          <Route path="support-service" element={<SupportService />} />
          <Route path="checkout/cart" element={<Checkout />} />
          <Route path="customer/student/register" element={<StudentRegister />} />
          <Route path="onestepcheckout" element={<OneStepCheckout />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </Suspense>

      {/* Footer */}
      <Footer />
    </main>
  )
}


const AppRoot = () => {

  return (
    <Routes>
      <Route path='/admin/*' element={<AdminApp />} />
      <Route path='/*' element={
        <App />
      } />
    </Routes>
  )
}

export default AppRoot