import { Navigate, useLocation } from "react-router-dom"



const OrderDetail = () => {
	const { state } = useLocation();
	const { orderDate, orderID, invoiceId, amountDue, amountPaid, walletAddress, status, items, name, phone, address, city } = state?.order ?? {};

	if (!state?.order) {
		return <Navigate to={"/admin/order"} replace={true} />
	}
	return (
		<div>
			<h1 className="font-bold text-3xl mb-8 border-b pb-2">{`Order ID: ${orderID}`}</h1>
			<div className="grid grid-cols-[200px_1fr]">
				<span className="font-bold border-b">Invoice ID</span>
				<span className="border-b">{invoiceId}</span>

				<span className="font-bold border-b">Ordered At</span>
				<span className="border-b">{(new Date(orderDate)).toLocaleString()}</span>

				<span className="font-bold border-b">Amount Due</span>
				<span className="border-b">{amountDue}</span>

				<span className="font-bold border-b">Amount Paid</span>
				<span className="border-b">{amountPaid}</span>

				<span className="font-bold border-b">Wallet Address</span>
				<span className="border-b">{walletAddress}</span>

				<span className="font-bold">Status</span>
				<span className="border-b">{status}</span>

			</div>

			<div className="mt-6">
				<h2 className="text-2xl font-bold">Client</h2>
				<div className="grid grid-cols-[200px_1fr]">
					<span className="font-bold border-b">Name</span>
					<span className="border-b">{name}</span>
					<span className="font-bold border-b">Phone</span>
					<span className="border-b">{phone}</span>
					<span className="font-bold border-b">City</span>
					<span className="border-b">{city}</span>
					{
						Object.entries(JSON.parse(address)).map(([key, val]) => (
							<>
								<span className="font-bold capitalize border-b" key={key}>{key}</span>
								<span className="border-b">{val}</span>
							</>
						))
					}
				</div>
			</div>
			<div className="mt-6">
				<h2 className="text-2xl font-bold">Items</h2>

				{
					items.map(({ name, description, price, images }, i) => (
						<div key={i} className="border-b py-4">
							<h3 className="font-bold text-lg text-blue-950">{name}</h3>
							<p className="text-gray-800">{description}</p>
							<p className=" border-blue-900 border w-fit rounded-full px-2 text-lg">{`₪ ${price.toLocaleString('en-US')}`}</p>
							<div className="grid grid-cols-2 md:grid-cols-4 gap-2 mt-6">
								{
									images.map((url, i) => (
										<img src={url} alt="" key={i} />
									))
								}
							</div>
						</div>
					))
				}
			</div>
		</div>
	)
}

export default OrderDetail;