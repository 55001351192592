import { Outlet, Route, Routes } from "react-router-dom"
import AdminLayout from "./layout/AdminLayout"
import AdminSignIn from "./pages/SignIn"
import "../assets/styles/tailwind.css";
import DashBoard from "./pages/Dashboard";
import Product from "./pages/Product";
import AddProduct from "./pages/NewProduct";
import { SnackbarProvider } from "notistack";
import ViewProduct from "./pages/ViewProductPage";
import AdminContextProvider from "./adminContext";
import BaseEdit from "./pages/BaseEdit";
import AddModel from "./pages/AddModel";
import UpdateModel from "./pages/UpdateModelPage";


export const AdminApp = () => {

	return (
		<SnackbarProvider>
			<AdminContextProvider>
				<Routes>
					<Route path="signin" element={<AdminSignIn />} />

					<Route path="" element={<AdminLayout />}>
						<Route index element={<DashBoard />} />

						<Route path=":product" element={<Outlet />}>
							<Route path="" index element={<Product />} />
							<Route path="add" element={<AddProduct />} />
							<Route path="detail/:productId" element={<ViewProduct />} />
							<Route path="detail/:productId/add_model" element={<AddModel />} />
							<Route path="detail/:productId/edit_model" element={<UpdateModel />} />
							<Route path="edit/:productId" element={<BaseEdit />} />
						</Route>
					</Route>
				</Routes>
			</AdminContextProvider>

		</SnackbarProvider>

	)
}