import { useCallback, useEffect, useState } from "react";
import { ADMIN_KEY } from "../constants";
import { getFullUrl } from "../utils";
import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { enqueueSnackbar } from "notistack";
import AddColorDialog from "../components/dialogs/AddColorDialog";
import Loader from "../components/Loader";
import DeleteDialog from "../components/dialogs/DeleteDialog";
import EditColorDialog from "../components/dialogs/EditColorDialog";


const columns = [
	{
		id: "name",
		label: "Name",
		minWidth: 120
	},
	{
		id: "hex",
		label: "Hex",
		minWidth: 120
	},
	{
		id: "preview",
		label: "Preview",
		minWidth: 120
	},
	{
		id: "actions",
		label: "Actions",
	},
]

const sampleColor = {
	name: "black",
	hex: "#000000"
}

const ColorPage = () => {

	const [colors, setColors] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [addDialog, setAddDialog] = useState(false);
	const [editDialog, setEditDialog] = useState(false);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [fetching, setFetching] = useState(true);
	const [requiresReload, setRequiresReload] = useState(false);

	const handleAddColorDialogClosed = (c) => {
		if (c) {
			setRequiresReload(!requiresReload)
		}

		setAddDialog(false)
		setEditDialog(false)
	}

	const handleDeleteSuccessful = (id) => {
		if (id) {
			setColors((cur) => cur.filter(c => c.id !== id));
		}
		setDeleteDialog(false);
	}

	const getColors = useCallback(async () => {

		const token = sessionStorage.getItem(ADMIN_KEY);

		const res = await fetch(getFullUrl("Color"), {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).catch(err => ({ status: 500 }))

		if (res.status === 200) {
			const colors = await res.json();
			setColors(colors);
		} else {
			enqueueSnackbar({
				message: "An error occurred fetching colors", variant: "error",
				autoHideDuration: 2000
			})
		}
		setFetching(false);

	}, [requiresReload])

	useEffect(() => {
		getColors();
	}, [requiresReload])

	return (
		<>
			<div className="flex justify-between">
				<h2 className="text-4xl font-bold mb-10 uppercase">Color</h2>

				<span>
					<Button variant="contained" sx={{ backgroundColor: "#014868" }} onClick={() => setAddDialog(true)}>Add new color</Button>
				</span>

			</div>
			{
				fetching ? (
					<Loader />
				)
					: (<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer sx={{ maxHeight: 440 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth }}
												sx={{ fontWeight: 700 }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{colors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
													{columns.map((column) => {
														const value = row[column.id];
														return column.id === "actions" ?
															(<TableCell key={column.id}>
																<div className='flex items-center mt-2 gap-3'>
																	<Button variant='contained' color='primary' onClick={() => setEditDialog(row)}
																		size="small">Edit</Button>
																	<Button variant='contained' color='error' onClick={() => setDeleteDialog(row.id)}
																		size="small">Delete</Button>
																</div>
															</TableCell>)
															: column.id === "preview" ?
																(
																	<TableCell key={column.id} align="center">
																		<div className="w-6 h-6 rounded-full" style={{ backgroundColor: row.hex }}>
																		</div>
																	</TableCell>

																)
																:
																(
																	<TableCell key={column.id} align={column.align}>
																		{column.format ? column.format(value)
																			: value}
																	</TableCell>
																);
													})}

												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50, 100]}
							component="div"
							count={colors.length}
							rowsPerPage={rowsPerPage}
							page={page}
							labelRowsPerPage="Products per page:"
							onRowsPerPageChange={(evt) => setRowsPerPage(evt.target.value)}
							onPageChange={(evt, page) => setPage(page)}
						/>
					</Paper>)}

			{
				addDialog && <AddColorDialog handleClose={handleAddColorDialogClosed} />
			}
			{
				editDialog && <EditColorDialog handleClose={handleAddColorDialogClosed} color={editDialog} />
			}
			{
				deleteDialog && <DeleteDialog title="color" id={deleteDialog} handleClose={handleDeleteSuccessful} />
			}
		</>
	)
}

export default ColorPage;