import React, { useCallback, useEffect, useState } from 'react';
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent
} from '../components';
import ShipImage from "../assets/images/fast_shipping.png";
import LocationPin from "../assets/images/location-pin.png";
import { useCart } from '../providers/CartProvider';
import { Element, scroller } from "react-scroll";

import {
  SEO,
  Carousel,
  CarouselItem,
  CartActionTray,
  ProductSwatchAttributeBox,
  Button
} from '../components';
import Icons from '../components/Icons';
import { useOffscreen } from '../providers';
import { useParams } from 'react-router-dom';
import { createValidationSchema, makeRequest } from '../lib/utils';
import Loader from '../admin/components/Loader';
import ScrollToTop from '../components/navigation/ScrollToTop';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { allMac } from '../recoil/mac/state';
import { allWatches } from '../recoil/watch/state';
import { allProductsFamily, singleCartItemModalOpen } from '../recoil';
import slugify from "@sindresorhus/slugify";
import { useAddProducts } from '../hooks/useAddProducts';
import { allPhone } from '../recoil/phone/state';
import { allIPad } from '../recoil/ipad/state';
import { useFormik } from 'formik';
import VisibilitySensor from "react-visibility-sensor";
import CartActionTrayMobile from '../components/CartActionTrayMobile';


const productDescriptionTabs = ["אחריות", "וידאו", "מפרט טכני", "מה בקופסה", "סקירה"];
const tabsEng = ["responsibility", "video", "specification", "whatsInBox", "review"];


const Description = ({ product, showTitle = true }) => {

  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const { openOffscreen, setType, setData } = useOffscreen();
  const setSingleCartItemModelOpen = useSetRecoilState(singleCartItemModalOpen);
  const { addCartItem } = useCart();
  const [newPrice, setNewPrice] = useState(product.price);

  const openCartGalleryModal = () => {
    // Set type
    setType("gallery-modal");

    // Set Data
    setData(product.imageUrls?.map((url, i) => ({
      title: `image ${i}`,
      imageSource: url
    })) ?? []);

    // Open modal
    openOffscreen();
  }

  const openBranchesModal = () => {
    setType("branches-modal");
    setData(product.branches ?? []);
    openOffscreen();
  }

  const rules = [];

  if (product?.colors && product?.colors?.length > 0) {
    rules.push({
      key: "productColor",
      type: "string",
      required: true,
      default: null
    })
  }
  if (product?.models && product?.models?.length > 0) {
    rules.push({
      key: "model",
      type: "number",
      required: true,
      default: null
    })
  }

  const initialValues = {};
  rules.forEach((r) => initialValues[r.key] = r.default);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setSingleCartItemModelOpen({ ...product, price: newPrice });
      if (values.productColor) {
        values.productColor = product.colors.filter(c => c.hex === values.productColor)[0].name;
      }
      const data = { ...product, price: newPrice, values };
      if (values.model) {
        data.modelId = product.models.filter(p => p.id === values.model)[0].id;
      }
      addCartItem(data);
    },
    validationSchema: createValidationSchema(rules),
  });

  const handleSubmit = (e) => {
    formik.handleSubmit(e);
    const errors = Object.keys(formik.errors);

    if (errors.length > 0) {
      scroller.scrollTo(errors[0], {
        offset: -(window.innerHeight / 2),
      });
    }
  }

  const handleModelChanged = (value) => {
    const model = product.models.filter(p => p.id === value)[0];
    formik.handleChange({ target: { name: "model", value } })
    setNewPrice(model.price);
  }

  return (
    <>
      <CartActionTrayMobile product={product} visible={!showTitle} onAddToCartClicked={handleSubmit} newPrice={newPrice} />

      <section className="wrapper relative">

        {/* Cart Description Section */}
        <section className="p-2 grid grid-cols-1 md:grid-rows-[min-content_1fr] md:grid-cols-[40%_60%]">
          {/* Cart Description Content Container */}
          <div className="px-8 row-start-1">
            {/* Cart Description Header */}
            <header className="text-center">

              <h2 className="text-1.4xl hidden md:block font-normal">{product?.name}</h2>

              {/* Shipping Date */}
              <div className="p-1 flex items-center justify-center gap-x-1">
                <span className='text-[#f00] text-[.84rem]'>לזמן האספקה המדוייק יש לבחור דגם</span>
                <span className="p-1">
                  <img
                    src={ShipImage}
                    alt="shipping"
                    className="h-[1.6rem]"
                  />
                </span>
              </div>

              {/* Branches */}
              <Button
                size="none"
                className="pt-1.5 pb-1 flex items-center justify-center gap-x-1 mx-auto"
                onClick={openBranchesModal}
              >
                <span className='text-app-text text-[.84rem]'>בדיקת מלאי בסניפים</span>
                <span className="p-1">
                  <img
                    src={LocationPin}
                    alt="shipping"
                    className="h-[1.6rem]"
                  />
                </span>
              </Button>

              {/* Validation Advice */}
              <p className='text-[#f00] text-[.84rem] mt-[.2rem] font-bold'>אנא בחר את תכונות המוצר לקבלת זמינות במלאי</p>

              {/* Description */}
              <div className='py-3 text-center'>
                <h2 className="text-grey-820 font-bold text-[1.05em]">{product?.description}</h2>
                {/*  <p className="text-grey-900 mt-2.5">ה-iPhone 15 וה-iPhone 15 Plus מביאים לכם את האי הדינמי, מצלמה ראשית ברזולוציית 48 מגה פיקסל, ומחבר USB-C חדש - הכל בתוך מעטפת אלומיניום וזכוכית משולבת בתהליך הזרקת צבע. </p>
                      <p className='text-accent-400 font-bold'>הטבה לרוכשים - שובר מתנה בסך 100 ש״ח (תישלח לאחר קבלת המכשיר).</p> */}
              </div>
            </header>
          </div>

          {/* Cart Description Images Container */}
          <main className="row-start-2 md:col-start-2 md:row-start-1 md:row-span-full">
            {/* Cart Description Images Container */}
            <section className="h-[480px] min-h-[31.2rem] mb-4">
              <Carousel
                itemsLength={product?.imageUrls?.length ?? 0}
                activeCarouselIndex={activeCarouselIndex}
                setActiveCarouselIndex={setActiveCarouselIndex}
                indicatorClassName="z-10"
                showIndicators={product?.imageUrls?.length > 1}
              >
                {product?.imageUrls?.map((url, index) => (
                  <CarouselItem
                    key={url}
                    index={index}
                    activeCarouselIndex={activeCarouselIndex}
                    className="flex justify-center"
                  >
                    <img
                      src={url}
                      alt=""
                      className='h-full w-[22.3rem] object-cover'
                    />
                  </CarouselItem>
                ))}
              </Carousel>
            </section>

            {/* Cart Gallery Button */}
            <div className='flex justify-end z-50'>
              <Button
                size="none"
                className="gap-x-2"
                onClick={openCartGalleryModal}
              >
                <span className='text-[0.82em] font-extralight'>הגדלה</span>
                <Icons.Search className="w-5 h-5 leading-4" />
              </Button>
            </div>
          </main>

          {/* Cart Swatch Attributes */}
          <div className="md:col-start-1 p-8 space-y-4">
            {/* Product Model Swatch */}
            {
              product.models?.length > 0 && <Element name="model">
                <ProductSwatchAttributeBox
                  title=":בחר דגם"
                  options={product.models.map(m => m.id)}
                  titles={product.models.map(m => m.title)}
                  optionsTitle={product.models.map(m => m.storageCapacity)}
                  selectedSwatchAttribute={formik.values.model}
                  setSelectedSwatchAttribute={(value) => handleModelChanged(value)}
                  info={formik.touched.model && formik.errors.model && " .שדה זה הוא חובה"}
                />
              </Element>
            }

            {/* Product Color Swatch */}
            {
              product?.colors?.length > 0 && <Element name='productColor'>
                <ProductSwatchAttributeBox
                  title=":בחר דגם"
                  options={
                    product.colors.map((c) => ({ color: c.hex, title: c.name }))
                  }
                  selectedSwatchAttribute={formik.values.productColor}
                  setSelectedSwatchAttribute={(value) => formik.handleChange({ target: { name: "productColor", value } })}
                  info={formik.touched.productColor && formik.errors.productColor && " .שדה זה הוא חובה"}
                />
              </Element>
            }
          </div>
        </section>
        {/* Cart Action Tray */}
        <CartActionTray product={product} onAddToCartClicked={handleSubmit} newPrice={newPrice} />

      </section>
    </>
  )
}


const ProductDescription = () => {
  const [loading, setLoading] = useState(false);
  //const [product, setProduct] = useState({});
  const { productCategory, productSubCategory, slug } = useParams();
  const [product, setProduct] = useRecoilState(allProductsFamily(slug ?? productSubCategory))
  const setWatches = useSetRecoilState(allWatches);
  const setMacs = useSetRecoilState(allMac);
  const setPhones = useSetRecoilState(allPhone);
  const setIPads = useSetRecoilState(allIPad);
  const cartModalOpen = useRecoilValue(singleCartItemModalOpen);
  const [tabs, setTabs] = useState(); //{default:string, tabs:[]}

  const [visible, setVisible] = useState(false)

  const addProducts = useAddProducts();

  const onVisible = (value) => {
    if (value) {
      setVisible(true)
    }
  }

  const getProductDetails = useCallback(async (product) => {
    setLoading(true);
    const path = productCategory?.toLowerCase() ?? "accessory";

    const res = await makeRequest(`${path}/${product.id}`);

    if (res.status < 300) {
      const phone = await res.json();
      setProduct((cur) => ({ ...phone, id: product.id, detailsFetched: true }));
    }
    setLoading(false);
  }, [])

  const getProducts = useCallback(async () => {
    setLoading(true);
    const path = productCategory?.toLowerCase() ?? "accessory";
    const res = await makeRequest(path);

    if (res.status < 300) {
      let products = await res.json();
      products = products.map((p) => ({ ...p, slug: slugify(p.name) }));

      if (path === "watch") {
        setWatches(products);
      } else if (path === "mac") {
        setMacs(products);
      } else if (path === "iphone") {
        setPhones(products);
      } else if (path === "ipad") {
        setIPads(products);
      }
      //setProduct(product)
      addProducts(products);
    }
    setLoading(false);
  }, [])

  useEffect(() => {

    if (!product) {
      getProducts();
      return
    } else if (product && !product.detailsFetched) {
      getProductDetails(product);
      return;
    }

    const _tabs = [];
    for (const [idx, t] of Object.entries(tabsEng)) {
      if (t === "responsibility" && product.responsibility) {
        _tabs.push(productDescriptionTabs[idx])
      }
      if (t === "video" && product.videoUrl) {
        _tabs.push(productDescriptionTabs[idx])
      }
      if (t === "specification" && product.specification) {
        _tabs.push(productDescriptionTabs[idx])
      }
      if (t === "whatsInBox" && product.whatsInBox?.length > 0) {
        _tabs.push(productDescriptionTabs[idx])
      }
      if (t === "review" && product.reviews?.length > 0) {
        _tabs.push(productDescriptionTabs[idx])
      }
    }

    if (_tabs.length > 0) {
      setTabs({
        default: _tabs[_tabs.length - 1],
        tabs: _tabs
      })
    }
  }, [product])

  if (cartModalOpen) {
    return (<>
      <SEO
        title={product?.name ?? "IStore"}
      />
    </>);
  }

  return (
    <main>
      <SEO
        title={product?.name ?? "IStore"}
      />
      <ScrollToTop />

      {
        loading ? (
          <div className='py-10 '>
            <Loader />
          </div>
        ) :
          !product?.detailsFetched ? (
            <div className='text-center font-bold py-8'>
              <p>לא ניתן לאחזר משאבים כרגע. נסה שוב מאוחר יותר</p>
            </div>
          ) : (
            <>
              <Description product={product} showTitle={!visible} />
            </>
          )
      }
      {/* Tabs */}
      {/* <Tabs tabs={} /> */}

      <Tabs defaultValue={tabs?.default}>
        <VisibilitySensor onChange={onVisible} active={Boolean(product)}>
          <TabsList>
            {tabs?.tabs.map(tab => (
              <TabsTrigger value={tab}>{tab}</TabsTrigger>
            ))}
          </TabsList>
        </VisibilitySensor>


        {/* Tab content */}
        <div className='bg-grey-360 border-y border-[#ccc]'>
          <div className="wrapper p-8">
            {/* Responsibilities */}
            {
              product?.responsibility && <TabsContent value="אחריות">
                <ul>
                  Responsibilities
                </ul>
              </TabsContent>
            }


            {/* Video */}
            <TabsContent value="וידאו">
              <div className='relative'>
                <iframe
                  src={product?.videoUrl}
                  style={{ width: "100%" }}
                  width="640"
                  height="612"
                  data-ratio="1.777"
                  title='kkk'
                  allowFullScreen=""
                />
              </div>
            </TabsContent>

            {/* Specification */}
            {
              product?.specification && <TabsContent value="מפרט טכני">
                <div className='text-end'>Specification</div>
              </TabsContent>
            }


            {/* Box */}
            <TabsContent value="מה בקופסה">
              <div className='text-end flex flex-col gap-3 font-bold'>
                {
                  product?.whatsInBox?.map((b, i) => (
                    <span key={i}>{b}</span>
                  ))
                }
              </div>
            </TabsContent>

            {/* Reviews */}
            <TabsContent value="סקירה">
              <div className='text-end flex flex-col gap-3'>
                <h2 className='font-bold'>{product?.name}</h2>
                {
                  product?.reviews?.map((r) => (
                    <div key={r.id} className='flex flex-col'>
                      <h3 className='font-bold'>{r.title}</h3>
                      <span>{r.content}</span>
                    </div>
                  ))
                }
              </div>
            </TabsContent>
          </div>
        </div>
      </Tabs>

      {/* Products Collection Overview */}
      <section className='py-4 my-4'>
        <div className='wrapper space-y-7'>
          {/* Header */}
          <header className='z-20 flex flex-col items-center'>
            <h4 className="text-[1.2rem] bg-white z-30 px-2">מוצרים נלווים</h4>
            <hr className='w-full border-app-border -mt-[.8rem] -z-10' />
          </header>

          {/* <ProductsCollectionOverview type="cartDescription" /> */}
        </div>
      </section>
    </main>
  )
}

export const BuyProductDescriptionRoot = () => {

  const { productCategory, productSubCategory, slug } = useParams();

  const product = useRecoilValue(allProductsFamily(slug))

  return product ? (<ProductDescription product={product} />) : null;
}

export default ProductDescription