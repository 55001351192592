import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import PropType from "prop-types";
import Portals from '../Portals';
import CartModal from './CartModal';
import UserFormModal from "./UserFormModal";
import GalleryModal from './GalleryModal';
import SubscribeModal from './SubscribeModal';
import BranchesModal from './BranchesModal';

const Modal = ({ type, closeModal, isOpen, data }) => {
  const modalContainerRef = useRef(null);
  const containerVariant = {
    "subscribe-modal": {
      initial: {
        opacity: 0
      },
      animate: {
        opacity: 1
      },
      transition: {
        delay: 1,
        duration: .15
      }
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleModalContainer);

    return () => window.removeEventListener("click", handleModalContainer);
  });

  const handleModalContainer = ({ target }) => {
    if(isOpen && target.contains(modalContainerRef.current))
      closeModal();
  }

  return (
    <Portals elementID="modal-root">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={type === "subscribe-modal" ? { delay: 1, duration: .15 } : { delay: .02, duration: .05 }}
        className='w-full h-full fixed top-0 left-0 bg-black/30 flex justify-center items-center z-[1000]'
        ref={modalContainerRef}
      >
        <AnimatePresence mode='wait'>
          {type === "cart-modal" ? (
            <CartModal closeModal={closeModal} />
          ) : type === "user-form-modal" ? (
            <UserFormModal closeModal={closeModal} />
          ) : type === "gallery-modal" ? (
            <GalleryModal 
              data={data}
              closeModal={closeModal}  
            />
          ) : type === "subscribe-modal" ? (
            <SubscribeModal closeModal={closeModal} />
          ) : type === "branches-modal" ? (
            <BranchesModal 
              data={data}
              closeModal={closeModal}
            />
          ) : null}
        </AnimatePresence>
      </motion.div>
    </Portals>
  )
}

Modal.propType = {
  type: PropType.string
}

export default Modal;