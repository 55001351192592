import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { enqueueSnackbar } from "notistack";
import { getFullUrl } from "../utils";
import { ADMIN_KEY } from "../constants";
import DeleteDialog from "../components/dialogs/DeleteDialog";

const columns = [
	{ id: 'orderID', label: 'Order ID', maxWidth: 80 },
	{ id: 'invoiceId', label: 'Invoice ID', minWidth: 80 },
	{ id: 'amountDue', label: 'Amount Due', minWidth: 80 },
	{ id: 'amountPaid', label: 'Amount Paid', minWidth: 80 },
	{ id: 'orderDate', label: 'Ordered At', minWidth: 120, format: (value) => (new Date(value)).toLocaleString() },
	{ id: 'walletAddress', label: 'Wallet Address', maxWidth: 120 },
	{ id: 'status', label: 'Status', minWidth: 120 },
	{ id: 'actions', label: 'Actions' }
]


const OrderPage = () => {

	const [rows, setRows] = useState([

	]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [dialog, setDialog] = useState(null);
	const [fetching, setFetching] = useState(true);
	const navigate = useNavigate();

	const handleViewClicked = (order) => {

		navigate(`detail/${order.orderID}`, {
			state: {
				order
			}
		});
	}

	const handleDeleteSuccessful = ({ orderID }) => {
		if (orderID) {
			setRows((cur) => cur.filter(c => c.orderID !== orderID));
		}
		setDialog(false);
	}


	const getOrders = useCallback(async () => {

		const token = sessionStorage.getItem(ADMIN_KEY);

		const res = await fetch(getFullUrl("order"), {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).catch(err => ({ status: 500 }))

		if (res.status === 200) {
			const branches = await res.json();
			setRows(branches);
		} else {
			enqueueSnackbar({
				message: "An error occurred fetching Orders", variant: "error",
				autoHideDuration: 2000
			})
		}
		setFetching(false);

	}, [])

	useEffect(() => {
		getOrders();
	}, [])

	return (
		<>
			<div className="flex">
				<h2 className="text-4xl font-bold mb-10 uppercase">Order</h2>
			</div>
			{
				fetching ? (
					<Loader />
				)
					:
					(<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer sx={{ maxHeight: 440 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth }}
												sx={{ fontWeight: 700 }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={row.orderID}>
													{columns.map((column) => {
														const value = row[column.id];
														return column.id === "actions" ?
															(<TableCell key={column.id}>
																<div className='flex items-center mt-2 gap-3'><Button variant='contained' color='primary' onClick={() => handleViewClicked(row)} size="small">View</Button>
																	<Button variant='contained' color='error' onClick={() => setDialog(row.id)}
																		size="small">Delete</Button></div>
															</TableCell>)
															: (
																<TableCell key={column.id} align={column.align}>
																	{column.format
																		? column.format(value)
																		: value}
																</TableCell>
															);
													})}

												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[25, 50, 100]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							labelRowsPerPage="Orders per page:"
							onRowsPerPageChange={(evt) => setRowsPerPage(evt.target.value)}
							onPageChange={(evt, page) => setPage(page)}
						/>
					</Paper>)}

			{
				dialog && <DeleteDialog title="order" id={dialog} handleClose={handleDeleteSuccessful} />
			}
		</>
	)
}

export default OrderPage;