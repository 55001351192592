import { useNavigate, useParams } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from "@mui/material";
import BranchDetailPage from "./BranchDetailsPage";
import { useCallback, useContext, useEffect, useState } from "react";
import Loader from "../components/Loader";
import ProductColorDialog from "../components/dialogs/ProductColorDialog";
import { makeRequest } from "../utils";
import { AdminContext } from "../adminContext";
import RemoveProductColor from "../components/dialogs/RemoveProductColor";
import ProductBranchDialog from "../components/dialogs/ProductBranchDialog";
import RemoveProductBranch from "../components/dialogs/RemoveProductBranch";
import AddReviewDialog from "../components/dialogs/AddReviewDialog";
import RemoveReviewDialog from "../components/dialogs/RemoveReviewDialog";
import EditReviewDialog from "../components/dialogs/EditReviewDialog";
import OrderDetail from "./OrderDetails";
import { Tabs, TabsList, TabsTrigger } from "../../components";
import { TabsContent } from "@radix-ui/react-tabs";
import DeleteModelDialog from "../components/dialogs/DeleteModelDialog";

const branchesColumns = [
	{ id: 'title', label: 'Title', minWidth: 120 },
	{ id: 'address', label: 'Address', minWidth: 120 },
	{ id: 'openingHours', label: 'Opening Hours', minWidth: 120 },
	{ id: 'phoneNumber', label: 'Phone Number', minWidth: 120 },
	{ id: 'action', label: 'Action', minWidth: 60 },
]

const reviewsColumns = [
	{ id: 'title', label: 'Title', minWidth: 120 },
	{ id: 'content', label: 'Content', minWidth: 180 },
	{ id: 'action', label: 'Action', minWidth: 60 },
]

const ViewProduct = () => {
	const { product, productId } = useParams();
	const [curProduct, setCurProduct] = useState(null);
	const [fetching, setFetching] = useState(true);
	const { name, description, price, videoUrl, imageUrls, dateAdded, whatsInBox, category, colors, branches, reviews, models } = curProduct ? curProduct : {};
	const { productTypes, accessoryCategories } = useContext(AdminContext)
	const productType = (() => {
		for (let [k, v] of Object.entries(productTypes.current)) {
			if (v[0].toLowerCase() === product[0]) {
				return k;
			}
		}
	})();

	const [colorDialog, setColorDialog] = useState(false)
	const [addBranchDialog, setAddBranchDialog] = useState(false)
	const [addReviewDialog, setAddReviewDialog] = useState(false)
	const [removeColorDialog, setRemoveColorDialog] = useState(false)
	const [removeReviewDialog, setRemoveReviewDialog] = useState(false)
	const [editReviewDialog, setEditReviewDialog] = useState(false)
	const [removeBranchDialog, setRemoveBranchDialog] = useState(false)
	const [editModelDialog, setEditModelDialog] = useState(false)
	const [removeModelDialog, setRemoveModelDialog] = useState(false)
	const [requireReload, setRequireReload] = useState(false);
	const navigate = useNavigate()

	const getProduct = useCallback(async () => {
		const res = await makeRequest(`${product}/${productId}`, "GET");

		if (res.status < 300) {
			const p = await res.json();
			setCurProduct(p);
		}
		setFetching(false);
	}, [requireReload])

	useEffect(() => {
		if (product === "branch" || product === "order") {
			return;
		}
		getProduct();
	}, [requireReload])

	const handleAddColorClicked = () => {
		setColorDialog(true)
	}

	const handleAddToBranchClicked = () => {
		setAddBranchDialog(true)
	}

	const handleClose = (c) => {
		if (c) {
			setCurProduct((cur) => ({ ...cur, colors: [...cur.colors, c] }))
		}
		setColorDialog(false);
	}

	const handleColorRemoved = (id) => {
		if (id) {
			setCurProduct((cur) => ({ ...cur, colors: cur.colors.filter(c => c.id !== id) }))
		}
		setRemoveColorDialog(false);
	}

	const handleReviewRemoved = (id) => {
		if (id) {
			setCurProduct((cur) => ({ ...cur, reviews: cur.reviews.filter(c => c.id !== id) }))
		}
		setRemoveReviewDialog(false);
	}


	const handleBranchRemoved = (id) => {
		if (id) {
			setCurProduct((cur) => ({ ...cur, branches: cur.branches.filter(c => c.id !== id) }))
		}
		setRemoveBranchDialog(false);
	}


	const handleEditClicked = () => {
		navigate(`/admin/${product}/edit/${productId}`)
	}

	const handleBranchAdded = (branch) => {
		if (branch) {
			setCurProduct((cur) => ({ ...cur, branches: [...cur.branches, branch] }))
		}

		setAddBranchDialog(false)
	}

	const handleProductReviewAdded = (status) => {
		if (status) {
			setRequireReload(!requireReload)
		}
		setAddReviewDialog(false)
		setEditReviewDialog(false)
	}

	const handleAddModelClicked = () => {
		navigate("add_model", {
			state: {
				productId,
				productType,
				name,
			}
		})
	}

	const handleEditModelClicked = (model) => {
		navigate("edit_model", {
			state: {
				productId,
				productType,
				name,
				model
			}
		})
	}

	const handleRemoveModel = (id) => {
		if (id) {
			setCurProduct((cur) => ({ ...cur, models: cur.models.filter(m => m.id !== id) }))
		}

		setRemoveModelDialog(false);
	}

	if (product === "branch") {
		return <BranchDetailPage />
	}

	if (product === "order") {
		return <OrderDetail />
	}
	return fetching ?
		(<Loader />)
		:
		curProduct ?
			(
				<div>
					<div className="text-4xl font-bold mb-6 flex justify-between"><h2>{name}</h2> <div className="flex gap-2 items-center">
						<span><Button size='small' onClick={handleEditClicked}>Edit</Button></span><span className="text-2xl border px-3 rounded-full flex items-center border-primary-300 h-fit">₪{`${price.toLocaleString('en-US')}`}</span></div></div>
					<p>{description}</p>
					<div className="justify-end flex gap-2">
						<span><Button size="small" variant="contained" onClick={handleAddColorClicked}>Add color</Button></span>
						<span><Button size="small" variant="contained" onClick={handleAddToBranchClicked}>Add to branch</Button></span>
						<span><Button size="small" variant="contained" onClick={() => setAddReviewDialog(true)}>Add Review</Button></span>
					</div>
					<div className="flex gap-1">
						{
							colors?.map((c) => (
								<Paper key={c.id} onClick={() => setRemoveColorDialog(c.id)}>
									<div className="w-6 h-6 rounded-full cursor-pointer" style={{ backgroundColor: c.hex }} title="click to remove">
									</div>
								</Paper>
							))
						}
					</div>

					<p><span className="font-bold">Added:</span><span className="italic text-sm">{` ${new Date(dateAdded).toLocaleString()}`}</span></p>

					<p><span className="font-bold">Category:</span>{` ${accessoryCategories.current[category]}`}</p>

					<div>
						<p className="font-bold">What's in the box</p>
						<ul className="pl-6">
							{
								whatsInBox?.map((i, idx) => (
									<li key={idx} className="list-disc">{i}</li>
								))
							}
						</ul>
					</div>
					<div className="grid grid-cols-3 my-6">
						{
							imageUrls?.map((url) => (
								<img src={url} alt="" key={url} />
							))
						}
					</div>

					{
						videoUrl && <div className="flex justify-center my-5"><video src={videoUrl} controls></video></div>
					}

					{
						['mac', 'phone', 'ipad'].includes(product) && <div>
							<h2 className="font-bold text-xl">Models</h2>
							<div className="flex justify-end">
								<span><Button size="small" variant="contained" onClick={handleAddModelClicked}>Add model</Button></span>

							</div>{
								models?.length > 0 && <div>

									<Tabs defaultValue={models[0].title}>
										<TabsList>
											{models.map(({ title }) => (
												<TabsTrigger value={title}>{title}</TabsTrigger>
											))}
										</TabsList>

										<div className='bg-grey-360 border-y border-[#ccc]'>
											<div className="wrapper p-8">

												{
													models.map(({ title, price, specs, storageCapacity, id }) => (
														<TabsContent value={title} key={title}>
															<div className="grid grid-cols-[150px_1fr]">
																<span className="col-span-2 flex justify-end gap-2">
																	<Button size="small" variant="contained" onClick={() => handleEditModelClicked({ title, price, specs, id })} color="primary">Update model</Button>
																	<Button size="small" variant="contained" onClick={() => setRemoveModelDialog(id)} color="error">Remove model</Button>
																</span>
																<span className="font-bold">Price</span>
																<span>₪{price}</span>
																<span className="font-bold">Storage capacity</span>
																<span>{storageCapacity}</span>
																<div className="col-span-2">
																	<h2 className="font-bold text-lg">Specs</h2>
																	<ul className="list-disc pl-4">
																		{
																			specs.map((s, i) => (
																				<li key={i} className="text-sm">{s}</li>
																			))
																		}
																	</ul>
																</div>
															</div>
														</TabsContent>
													))
												}
											</div>
										</div>
									</Tabs>
								</div>
							}

						</div>
					}

					<div className="my-4">
						<h2 className="font-bold text-xl">Branches</h2>
						<Paper sx={{ width: '100%', overflow: 'hidden' }}>
							<TableContainer sx={{ maxHeight: 440 }}>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{branchesColumns.map((column) => (
												<TableCell
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
													sx={{ fontWeight: 700 }}
												>
													{column.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{branches?.map((row) => {
											return (
												<TableRow key={row.id}>
													{branchesColumns.map((column) => {
														const value = row[column.id];
														return column.id === "action" ? (
															<TableCell key={column.id} align={column.align}>
																<Button variant='contained' color='error' onClick={() => setRemoveBranchDialog(row.id)}
																	size="small">Remove</Button>
															</TableCell>
														) :
															(
																<TableCell key={column.id} align={column.align}>
																	{column.format
																		? column.format(value)
																		: value}
																</TableCell>
															);
													})}

												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</div>



					<div className="my-4">
						<h2 className="font-bold text-xl">Reviews</h2>
						<Paper sx={{ width: '100%', overflow: 'hidden' }}>
							<TableContainer sx={{ maxHeight: 440 }}>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{reviewsColumns.map((column) => (
												<TableCell
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
													sx={{ fontWeight: 700 }}
												>
													{column.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{reviews?.map((row) => {
											return (
												<TableRow key={row.id}>
													{reviewsColumns.map((column) => {
														const value = row[column.id];
														return column.id === "action" ? (
															<TableCell key={column.id} align={column.align}>
																<Button variant='contained' color='primary' onClick={() => { }}
																	size="small" sx={{ marginRight: "8px", marginBottom: "4px", }} onClickCapture={() => setEditReviewDialog(row)}>Edit</Button>
																<Button variant='contained' color='error' onClick={() => setRemoveReviewDialog(row.id)}
																	size="small">Remove</Button>
															</TableCell>
														) : (
															<TableCell key={column.id} align={column.align}>
																{column.format
																	? column.format(value)
																	: value}
															</TableCell>
														);
													})}

												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</div>

					{
						colorDialog && <ProductColorDialog handleClose={handleClose} productId={parseInt(productId)} productType={parseInt(productType)} />
					}
					{
						addBranchDialog && <ProductBranchDialog handleClose={handleBranchAdded} productId={parseInt(productId)} productType={parseInt(productType)} />
					}
					{
						addReviewDialog && <AddReviewDialog handleClose={handleProductReviewAdded} productId={parseInt(productId)} productType={parseInt(productType)} />
					}
					{
						editReviewDialog && <EditReviewDialog handleClose={handleProductReviewAdded} productId={parseInt(productId)} productType={parseInt(productType)} review={editReviewDialog} />
					}
					{
						removeColorDialog && <RemoveProductColor handleClose={handleColorRemoved} productId={parseInt(productId)} productType={parseInt(productType)} colorId={removeColorDialog} />
					}
					{
						removeReviewDialog && <RemoveReviewDialog handleClose={handleReviewRemoved} productId={parseInt(productId)} productType={parseInt(productType)} reviewId={removeReviewDialog} />
					}

					{
						removeBranchDialog && <RemoveProductBranch handleClose={handleBranchRemoved} productId={parseInt(productId)} productType={parseInt(productType)} branchId={removeBranchDialog} />
					}

					{
						removeModelDialog && <DeleteModelDialog productId={+productId} productType={+productType} id={removeModelDialog} handleClose={handleRemoveModel} />
					}

				</div>
			) :
			(
				<div className='text-center'><span className='font-bold text-2xl '>There was an error fetching the details</span></div>
			)
}

export default ViewProduct;