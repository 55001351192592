import { useParams } from "react-router-dom";
import EditBranchPage from "./EditBranch";
import EditProduct from "./EditProduct";
import { useCallback, useEffect, useState } from "react";
import { makeRequest } from "../utils";
import Loader from "../components/Loader";



export default function BaseEdit() {
	const { product, productId } = useParams()
	const [curProduct, setCurProduct] = useState(null)
	const [fetching, setFetching] = useState(true);

	const getProduct = useCallback(async () => {
		const res = await makeRequest(`${product}/${productId}`, "GET");

		if (res?.status < 300) {
			setCurProduct(await res.json())
		}
		setFetching(false);
	}, [])

	useEffect(() => {
		getProduct();
	}, [])

	return fetching ?
		(<Loader />) :
		curProduct ?
			product === "branch" ? (<EditBranchPage branch={curProduct} />) :
				(<EditProduct curProduct={curProduct} />) :
			(
				<div className='text-center'><span className='font-bold text-2xl '>There was an error fetching the details of the {product}</span></div>
			)
}