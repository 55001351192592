import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

export const CartContext = createContext({
    cart: [],
    addCartItem: () => {},
    removeCartItem: () => {},
    clearCart: () => {},
    updateCartItemCount: () => {},
    cartCount: 0,
    totalCartPrice: 0
}); 

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const [cartCount, updateCartCount] = useState(0);
    const [totalCartPrice, setTotalCartPrice] = useState(0);

    const setCartItemsFromStorage = useCallback(() => {
        const storage = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [];
        setCart(storage);
    }, []);

    const calculateTotalCartPrice = useMemo(() => {
        let totalPrice = cart.reduce((a,c) => a + (c.price * c.quantity), 0);
        return totalPrice.toFixed(2);
    }, [cart]);

    // Calculate total cart price
    useEffect(() => {
        setTotalCartPrice(calculateTotalCartPrice);
    }, [calculateTotalCartPrice]);

    useEffect(() => {
        const count = cart.reduce((a, c) => a + c.quantity, 0);
        updateCartCount(count);
    }, [cart]);

    // Update cart data
    useEffect(() => {
        setCartItemsFromStorage();
    }, [setCartItemsFromStorage]);

    // Add cart item
    const addCartItem = (payload) => {
        const existingCartItem = cart.find(({ id }) => payload.id === id);

        if(existingCartItem) {
            const newCart = cart.map(cart => 
                cart.id === payload.id ? 
                    {...cart, quantity: cart.quantity + 1} 
                : 
                    cart
            );

            setCart(newCart);

            // Add cart storage
            addCartToStorage(newCart);
        } else {
            const newCart = {...payload, quantity: 1};
            setCart([
                ...cart,
                newCart
            ]);

            // Add cart storage
            addCartToStorage(cart);
        }
    } 

    // Store cart data in storage
    const addCartToStorage = (data) => {
        const strignifiedCart = JSON.stringify(data);
        localStorage.setItem("cart", strignifiedCart);
    }

    // Remove Cart Item
    const removeCartItem = (cartId) => {
        const existingCartItem = cart.find(({ id }) => id === cartId);

        if(existingCartItem) {
            // Remove cart Item
            const newCart = cart.filter(cart => cart.id !== existingCartItem.id)
            setCart(newCart);

            // Update cart storage
            addCartToStorage(newCart);
        }
    }

    // Clear cart items
    const clearCart = () => {
        // Clear cart state
        setCart([]);

        // Delete cart storage
        localStorage.removeItem("cart");
    }

    // Increment Cart Item Count 
    const updateCartItemCount = (cartId, count) => {
        const existingCartItem = cart.find(({ id }) => id === cartId);

        if(existingCartItem) {
            const incrementedCartItemCount = cart.map(cart => 
                cart.id === cartId ?
                    {
                        ...cart,
                        quantity: count
                    }
                : 
                    cart
            ); 
            
            // Update cart State
            setCart(incrementedCartItemCount);

            // Update cart storage data
            addCartToStorage(incrementedCartItemCount);
        }
    }

    // Increment Cart Item Count 
    // const decrementCartItemCount = (cartId) => {
    //     const existingCartItem = cart.find(({ id }) => id === cartId);

    //     if(existingCartItem && existingCartItem.quantity !== 0) {
    //         // Decrement Cart Item Count
    //         const decrementedCartItemCount = cart.map(cart => 
    //             cart.id === cartId ?
    //                 {
    //                     ...cart,
    //                     quantity: cart.quantity - 1
    //                 }
    //             : 
    //                 cart
    //         ); 

    //         setCart(decrementedCartItemCount);
    //     } else {
    //         // Remove cart item if quantity is equal to 0
    //         const newCart = cart.filter(cart => cart.id !== cartId);
    //         setCart(newCart); 
    //     }
    // }

    return (
        <CartContext.Provider
            value={{
                cart,
                cartCount,
                addCartItem,
                removeCartItem,
                clearCart,
                totalCartPrice,
                updateCartItemCount
            }}
        >
            {children}
        </CartContext.Provider>
    )
}

export const useCart = () => {
    const cartContext = useContext(CartContext);

    if(!cartContext) 
        throw new Error("Context must be in the CartProvider");

    return cartContext;
}