import React, { useEffect, useState } from 'react'
import Icons from '../Icons';
import LocationPin from "../../assets/images/location-pin.png";



const BranchesModal = ({ data, closeModal }) => {
    const [activeBranch, setActiveBranch] = useState();

    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        setActiveBranch(data[0]);
    }, [data]);


    return (
        <div className="w-[90%] h-[90vh] rounded-md bg-gray-100 shadow-md flex flex-col">
            {/* Header */}
            <div className='h-[16%] border-b relative text-center py-4'>
                {/* Close Modal Button */}
                <span
                    onClick={closeModal}
                    className='cursor-pointer select-none inline-block absolute top-10 right-0'
                    style={{ transform: "translate(-50%, -50%)" }}
                >
                    <Icons.Close size={56} color='gray' strokeWidth={1} />
                </span>

                <h2 className='text-3xl font-light'>זמינות מלאי בסניפים</h2>
            </div>

            {
                activeBranch && <>

                    {/* Body */}
                    <div className='flex flex-grow'>
                        {/* Desciption */}
                        <div className='description h-full w-3/5 border-r p-3'>
                            <figure className='w-full h-[70%] bg-grey-250 p-1.5'>
                                <img
                                    src={activeBranch.images[0]}
                                    alt={activeBranch.title}
                                    className="w-full h-full object-cover"
                                />
                            </figure>

                            <div className='flex items-center w-full justify-between'>
                                <div className='border-r w-1/2 text-center px-5'>
                                    <h3>שעות פעילות</h3>
                                    <p>{activeBranch.openingHours}</p>
                                </div>
                                <div className='w-1/2 text-center px-10'>
                                    <p>{activeBranch.address}</p>
                                    <p>{`Phone ${activeBranch.phoneNumber}`}</p>
                                </div>
                            </div>
                        </div>

                        {/* Branches List */}
                        <div className='h-full overflow-y-scroll flex-grow'>
                            {data.map(({ title, address, id }) => (
                                <blockquote
                                    key={title}
                                    className={`py-6 px-4 shadow flex items-center justify-end gap-6 cursor-pointer ${activeBranch.id === id ? 'bg-gray-50' : ''}`}
                                    onClick={() => setActiveBranch(data.filter(d => d.id === id)[0])}
                                >
                                    <div className='text-right'>
                                        <h1 className='text-lg mb-2'>{title}</h1>
                                        <div className='flex flex-col'>
                                            <p>{address}</p>
                                            <p className='text-[#6bb300] font-light'>זמין בסניף</p>
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src={LocationPin}
                                            alt="shipping"
                                            className="h-[1.9rem]"
                                        />
                                    </div>
                                </blockquote>
                            ))}
                        </div>
                    </div>
                </>
            }

        </div>
    )
}

export default BranchesModal