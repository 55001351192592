import React, { useState } from 'react';
import LoginForm from '../LoginForm';
import { Button } from '../ui/Button';
import CloseIcon from "../../assets/images/close.png";
import RegistrationForm from '../RegistrationForm';
import ForgotPasswordForm from '../ForgotPasswordForm';
import AccountForm from '../AccountForm';
import { useRecoilValue } from 'recoil';
import { user } from '../../recoil';
import DisconnectForm from '../DisconnectModal';

const UserFormModal = ({ closeModal }) => {
  const currentUser = useRecoilValue(user);
  const [formToDisplay, getFormToDisplay] = useState(currentUser ? "account" : "login");

  return (
    <div className='relative w-96 grid grid-cols-[1fr_max-content] items-start mt-6 mx-2 rounded overflow-hidden'>
      <blockquote className='bg-white w-full'>
        {/* Login Form */}
        {formToDisplay === "login" && (
          <LoginForm getFormToDisplay={getFormToDisplay} closeModal={closeModal} />
        )}

        {/* R egistration Form */}
        {formToDisplay === "register" && (
          <RegistrationForm getFormToDisplay={getFormToDisplay} />
        )}

        {/* Login Form */}
        {formToDisplay === "forgotPassword" && (
          <ForgotPasswordForm getFormToDisplay={getFormToDisplay} />
        )}

        {/* User Form */}
        {formToDisplay === "account" && (
          <AccountForm getFormToDisplay={getFormToDisplay} />
        )}


        {/* Disconnect Dialog */}
        {formToDisplay === "disconnect" && (
          <DisconnectForm getFormToDisplay={getFormToDisplay} closeModal={closeModal} />
        )}
      </blockquote>

      {/* Close Button */}
      <Button 
        onClick={closeModal} 
        className="top-2 absolute right-2"
      >
        <img 
          src={CloseIcon}
          alt="close user form modal"
          className="w-4 h-4"
        />
      </Button>
    </div>
  )
}

export default UserFormModal