import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import * as yup from "yup";
import { useFormik } from "formik";
import { ADMIN_KEY, BASE_URL } from "../constants";
import { getFullUrl } from "../utils";
import { enqueueSnackbar } from "notistack";
import LoadingScreen from "../components/LoadingScreen";
import AddBranchPage from "./NewBranchPage";
import { AdminContext } from "../adminContext";

const validationSchema = yup.object({
	name: yup.string().required("Name is required"),
	description: yup.string().required("Description is required"),
	imageURL: yup.string().required("Image URL is required"),
	videoURL: yup.string(),
	price: yup.number().required("Price is required"),
	category: yup.number()
})


const AddProduct = () => {

	const { product } = useParams();
	const [moreImageURL, setMoreImageURL] = useState({});  //{key:value}
	const [boxItems, setBoxItems] = useState({});  //{key:value}
	const [loading, setLoading] = useState(false);
	const [categories, setCategories] = useState({});
	const currentIndex = useRef(0);
	const navigate = useNavigate();
	const { accessoryCategories } = useContext(AdminContext);

	const generateKey = () => {
		currentIndex.current += 1;
		return currentIndex.current;
	};

	const handleAddMoreImageUrlField = () => {
		const key = generateKey();
		setMoreImageURL((cur) => ({ ...cur, [key]: "" }))
	}

	const handleAddMoreBoxItem = () => {
		const key = generateKey();
		setBoxItems((cur) => ({ ...cur, [key]: "" }))
	}

	const handleRemoveImageUrlField = (key) => {
		const newFields = { ...moreImageURL };
		delete newFields[key];
		setMoreImageURL(newFields);
	}

	const handleRemoveBoxItem = (key) => {
		const newFields = { ...boxItems };
		delete newFields[key];
		setBoxItems(newFields);
	}

	const handleImageUrlChange = (key, value) => {
		setMoreImageURL((cur) => ({ ...cur, [key]: value }));
	}

	const handleBoxItemChange = (key, value) => {
		setBoxItems((cur) => ({ ...cur, [key]: value }));
	}

	const formik = useFormik({
		initialValues: {
			name: "",
			description: "",
			imageURL: "",
			price: 0,
			category: -1
		},

		validationSchema,

		onSubmit: async (values) => {
			const token = sessionStorage.getItem(ADMIN_KEY);

			const body = {
				name: values.name,
				description: values.description,
				price: values.price,
				videoUrl: values.videoURL,
				imageUrls: [values.imageURL, ...Object.values(moreImageURL)],
				whatsInBox: Object.values(boxItems)
			}

			if (product === "accessory") {
				body.category = parseInt(values.category);
			}

			setLoading(true);
			try {
				const response = await fetch(getFullUrl(product), {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					},
					body: JSON.stringify(body)
				}).catch();

				if (response?.status < 300) {
					enqueueSnackbar({
						message: `New ${product} created`, variant: "success"
					})
					navigate(`/admin/${product}`, {
						replace: true
					})
				} else {
					enqueueSnackbar({
						message: "An error occurred", variant: "error"
					})
				}
			} catch (error) {
				enqueueSnackbar({
					message: "An error occurred", variant: "error"
				})
			}

			setLoading(false);
		}
	})

	const getCategories = useCallback(async () => {
		if (accessoryCategories.current) {
			setCategories(accessoryCategories.current);
			return;
		}
		const res = await fetch(`${BASE_URL}category/get-accessory-categories`).catch(err => ({ status: 500 }));

		if (res.status < 300) {
			setCategories(await res.json())
		}
	}, [])

	useEffect(() => {
		if (product === "branch") {
			return
		}
		getCategories()
	}, [])

	if (product === "branch") {
		return <AddBranchPage />
	}
	return (
		<div>
			<h2 className="text-4xl font-bold mb-10">New <span className="uppercase">{product}</span></h2>
			<div className="grid grid-cols-2 gap-6">
				<TextField
					type="text"
					label="Name"
					name="name"
					required

					value={formik.values.name}
					onChange={formik.handleChange}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
				/>
				<TextField
					type="number"
					label="Price"
					name="price"
					required

					value={formik.values.price}
					onChange={formik.handleChange}
					error={formik.touched.price && Boolean(formik.errors.price)}
					helperText={formik.touched.price && formik.errors.price}
				/>

				<div className="col-span-2">
					<TextField
						type="text"
						label="Description"
						name="description"
						multiline
						minRows={3}
						maxRows={6}
						fullWidth
						required

						value={formik.values.description}
						onChange={formik.handleChange}
						error={formik.touched.description && Boolean(formik.errors.description)}
						helperText={formik.touched.description && formik.errors.description}
					/>
				</div>

				<div className="col-span-2 flex flex-col">
					<TextField
						type="text"
						label="Image URL"
						name="imageURL"
						sx={{ width: "50%" }}
						required

						value={formik.values.imageURL}
						onChange={formik.handleChange}
						error={formik.touched.imageURL && Boolean(formik.errors.imageURL)}
						helperText={formik.touched.imageURL && formik.errors.imageURL}
					/>

					<div className="flex flex-col gap-4 mt-4">
						{
							Object.keys(moreImageURL).sort().map((key) => (
								<div className="flex items-center">
									<TextField
										type="text"
										label="Image URL"
										name={`imageURL${key}`}
										sx={{ width: "50%" }}
										key={key}
										value={moreImageURL[key]}
										onChange={(evt) => handleImageUrlChange(key, evt.target.value)}
									/>

									<IconButton onClick={() => handleRemoveImageUrlField(key)}>
										< DeleteIcon color="error" />
									</IconButton>
								</div>
							))
						}
					</div>

					<span className="bg-primary-500 text-white rounded-full px-4 py-2 cursor-pointer w-fit mt-2" onClick={handleAddMoreImageUrlField}>Add another image</span>

				</div>


				<TextField
					type="text"
					label="Video URL"
					name="videoURL"

					value={formik.values.videoURL}
					onChange={formik.handleChange}
					error={formik.touched.videoURL && Boolean(formik.errors.videoURL)}
					helperText={formik.touched.videoURL && formik.errors.videoURL}
				/>

				{
					product === "accessory" && (

						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={formik.values.category}
							onChange={formik.handleChange}
							fullWidth
							name="category"
							sx={{ marginLeft: 1 }}

						>

							<MenuItem value={-1} disabled>Select a category</MenuItem>
							{
								Object.entries(categories).map(([k, v]) => (
									<MenuItem key={k} value={k}>{v}</MenuItem>
								))
							}
						</Select>

					)
				}

				<div className="col-span-2 flex flex-col">
					<Typography variant="h6" fontWeight={600}>
						What's in the box?
					</Typography>
					<div className="flex flex-col gap-4 mt-4">

						{
							Object.keys(boxItems).sort().map((key) => (
								<div className="flex items-center">
									<TextField
										type="text"
										label="Single Item/Description"
										name={`boxItem${key}`}
										sx={{ width: "50%" }}
										key={key}
										value={boxItems[key]}
										onChange={(evt) => handleBoxItemChange(key, evt.target.value)}
									/>

									<IconButton onClick={() => handleRemoveBoxItem(key)}>
										< DeleteIcon color="error" />
									</IconButton>
								</div>
							))
						}
					</div>

					<span className="bg-primary-500 text-white rounded-full px-4 py-2 cursor-pointer w-fit mt-2" onClick={handleAddMoreBoxItem}>Add item</span>
				</div>

				<div className="col-span-2 flex justify-center mt-4">
					<span className="bg-primary-500 text-white rounded-full px-4 py-2 cursor-pointer w-1/2 mt-2 text-center" onClick={formik.handleSubmit}>SAVE</span>
				</div>
			</div>

			<LoadingScreen open={loading} />
		</div>
	)
}

export default AddProduct;