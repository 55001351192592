import React, { useState } from 'react';
import UserImage from "../assets/svg/user.svg";
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import FacebookButton from './FacebookButton';
import { Link } from 'react-router-dom';
import * as yup from "yup";
import { useFormik } from 'formik';
import loader from "../assets/images/loading.gif";
import { makeRequest } from '../lib/utils';
import { useSetRecoilState } from 'recoil';
import { user } from '../recoil';
import { USER_KEY } from '../lib/constants';

const validationSchema = yup.object({
    username: yup.string().required("שדה זה הוא חובה."),
    password: yup.string().required("שדה זה הוא חובה."),
});

const LoginForm = ({ getFormToDisplay, closeModal }) => {
    const setUser = useSetRecoilState(user);
    const [error, setError] = useState();
    const formik = useFormik({
        initialValues: {
            username: "",
            password: ""
        },
        validationSchema,

        onSubmit: async (values) => {
            const res = await makeRequest("login", "POST", values);

            if (res.status < 300) {
                const data = await res.json();
                sessionStorage.setItem(USER_KEY, data.token);
                setUser({
                    firstName: data.firstname,
                    lastName: data.lastname,
                    email: values.username
                });
                closeModal();
            } else if (res.status === 400) {
                setError("שם משתמש או סיסמה לא חוקיים");
            }
        }
    })
    return (
        <div className='w-full'>
            <header className='py-12 px-8 flex items-center justify-center bg-grey-400 border-b border-app-border'>
                <img
                    src={UserImage}
                    alt="user auth"
                    loading="lazy"
                    className='h-16'
                />
            </header>
            {
                error && <div className='flex justify-center mt-2 text-sm text-red-600'>
                    <p>{error}</p>
                </div>
            }
            {/* Form */}
            <form className='px-4 py-6 grid gap-y-3.5'>
                <Input
                    type="email"
                    name="username"
                    placeholder='כתובת דוא"ל'
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={formik.touched.username && formik.errors.username}
                    className="direction-rtl"
                />

                <Input
                    type="password"
                    name="password"
                    placeholder="סיסמה"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && formik.errors.password}
                    className="direction-rtl"
                />

                {/* Form Action Buttons */}
                <div className='space-y-2 mt-1'>
                    {
                        formik.isSubmitting ? (<div className='flex justify-center'>
                            <img src={loader} alt="loading" />
                        </div>) :
                            (<Button
                                variant="primary"
                                title="form submit button"
                                className="rounded-full"
                                width="full"
                                onClick={() => {
                                    if (error) {
                                        setError(null);
                                    }
                                    formik.handleSubmit();
                                }}
                                type="button"
                            >
                                התחברות
                            </Button>)
                    }

                    {/* Facebook Login Button */}
                    <FacebookButton />
                </div>

                {/* Form Question */}
                <div className='mx-auto mt-2 direction-rtl'>
                    <Link
                        href="void(0)"
                        className='text-app-text underline text-base'
                        onClick={() => getFormToDisplay("register")}
                    >
                        הרשמה
                    </Link>
                    <span className='text-base'>/</span>
                    <Link
                        href="void(0)"
                        className='text-app-text underline text-base'
                        onClick={() => getFormToDisplay("forgotPassword")}
                    >
                        שכחת סיסמה?
                    </Link>
                </div>
            </form>
        </div>
    )
}

export default LoginForm