import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { enqueueSnackbar } from "notistack";
import { getFullUrl } from "../utils";
import { ADMIN_KEY } from "../constants";
import DeleteDialog from "../components/dialogs/DeleteDialog";

const columns = [
	{ id: 'id', label: 'Branch ID', minWidth: 80 },
	{ id: 'title', label: 'Title', minWidth: 120 },
	{ id: 'address', label: 'Address', minWidth: 120 },
	{ id: 'openingHours', label: 'Opening Hours', minWidth: 120 },
	{ id: 'phoneNumber', label: 'Phone Number', minWidth: 120 },
	{ id: 'actions', label: 'Actions' }
]


const BranchPage = () => {

	const [rows, setRows] = useState([

	]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [dialog, setDialog] = useState(null);
	const [fetching, setFetching] = useState(true);
	const navigate = useNavigate();

	const handleViewClicked = (p) => {

		navigate(`detail/${p.id}`);
	}

	const handleDeleteSuccessful = (id) => {
		if (id) {
			setRows((cur) => cur.filter(c => c.id !== id));
		}
		setDialog(false);
	}


	const getColors = useCallback(async () => {

		const token = sessionStorage.getItem(ADMIN_KEY);

		const res = await fetch(getFullUrl("Branch"), {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).catch(err => ({ status: 500 }))

		if (res.status === 200) {
			const branches = await res.json();
			setRows(branches);
		} else {
			enqueueSnackbar({
				message: "An error occurred fetching Branches", variant: "error",
				autoHideDuration: 2000
			})
		}
		setFetching(false);

	}, [])

	useEffect(() => {
		getColors();
	}, [])

	return (
		<>
			<div className="flex justify-between">
				<h2 className="text-4xl font-bold mb-10 uppercase">Branch</h2>
				<Link to="add">
					<Button variant="contained" sx={{ backgroundColor: "#014868" }}>Add new Branch</Button></Link>
			</div>
			{
				fetching ? (
					<Loader />
				)
					:
					(<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer sx={{ maxHeight: 440 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth }}
												sx={{ fontWeight: 700 }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
													{columns.map((column) => {
														const value = row[column.id];
														return column.id === "actions" ?
															(<TableCell key={column.id}>
																<div className='flex items-center mt-2 gap-3'><Button variant='contained' color='primary' onClick={() => handleViewClicked(row)} size="small">View</Button>
																	<Button variant='contained' color='error' onClick={() => setDialog(row.id)}
																		size="small">Delete</Button></div>
															</TableCell>)
															: (
																<TableCell key={column.id} align={column.align}>
																	{column.format && typeof value === 'number'
																		? column.format(value)
																		: value}
																</TableCell>
															);
													})}

												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50, 100]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							labelRowsPerPage="Products per page:"
							onRowsPerPageChange={(evt) => setRowsPerPage(evt.target.value)}
							onPageChange={(evt, page) => setPage(page)}
						/>
					</Paper>)}

			{
				dialog && <DeleteDialog title="branch" id={dialog} handleClose={handleDeleteSuccessful} />
			}
		</>
	)
}

export default BranchPage;