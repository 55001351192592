import React, { useEffect } from 'react';
import ProductsCollectionItem from './ProductsCollectionItem';

const ProductsCollectionOverview = ({ type, products = [], showColor = true }) => {
    return (
        <section className='flex justify-center flex-wrap gap-4 transition-all duration-1000 px-1 sm:px-0 h-max'>
            {
                products.map((p) => (
                    <ProductsCollectionItem key={p.id} type={type} product={p} showColor={showColor} />
                ))
            }

        </section>
    )
}

export default ProductsCollectionOverview