import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { orderMade } from '../recoil';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';

const OrderSuccessfulTray = () => {
	const [modalOpen, setModalOpen] = useRecoilState(orderMade); //product
	const { address, total_amount } = typeof modalOpen === "boolean" ? {} : modalOpen;
	const location = useLocation();

	useEffect(() => {
		if (modalOpen) {
			setModalOpen(false);
		}
	}, [location]);

	if (!modalOpen) {
		return null;
	}

	return (
		<div className='w-screen flex items-center justify-center border-t'>
			<div className="w-[80%] h-fit py-4 min-h-[80px] z-40 border-b border-[#d6d6d5] bg-white pt-2 flex justify-center items-center gap-4 flex-col direction-rtl">

				<h2 className='font-bold md:text-2xl'>ההזמנה בוצעה בהצלחה</h2>
				<div className='md:min-w-[300px] w-screen md:w-full grid grid-cols-1 text-sm px-4'>
					<span className='font-bold'>כתובת הארנק</span>
					<span className='break-words w-[90%] md:w-full'>{address}</span>
					<span className='font-bold mt-2'>הכמות הכוללת</span>
					<span>{`btc ${total_amount.btc}`}</span>
					<div className='text-red-700 flex gap-3 font-bold py-4'>
						<InfoIcon />
						<span>התשלום אמור להתבצע תוך 10 דקות</span>
					</div>
				</div>
			</div>
		</div>

	)
}

export default OrderSuccessfulTray;