import React from 'react'
import ProductDescriptionBannerLinkContainer from './ProductDescriptionBannerLinks';

const SelectionProductItem = ({ imageSource, title, color, details, specs, prices, links, index, productLength }) => {
    const { Icon, content } = details;

    return (
        <blockquote className={`w-full max-w-full sm-lg:w-[41.666667%] sm-lg:max-w-[41.666667%] ${((productLength === 2 && index === 2) || (productLength === 3 && index === 3)) ? "" : "border-b border-[#a4a4a4]"} pb-10 sm-lg: sm-lg:pb-0 sm-lg:border-0 xl:w-[28.33333%] xl:max-w-[28.33333%]`}>
            <figure>
                <img 
                    src={imageSource}
                    alt="מקבוק אייר 13 אינץ׳"
                    loading='lazy'
                    className="w-full h-auto object-cover"
                />
            </figure>
            <h2 className="text-[20px] font-bold -mt-10 leading-[1.26667] tracking-[.009em] text-app-text-dark-9 text-center">{title}</h2>

            {/* Colors */}
            <div>
                <p className="text-app-text-dark-9 text-xs -tracking-[.01em] mt-2.5 mb-[7px]">{color.title}</p>
                <span 
                    style={{ background: `${color.color}` }}
                    className="inline-block w-7 h-7 xl:w-8 xl:h-8 rounded-full shadow-selectionProductColor"
                />
            </div>

            {/* Details */}
            <div className="my-5">
                <Icon className="w-[57px] h-[57px] sm-lg:w-[53px] sm-lg:h-[53px] xl:w-[60px] xl:h-[60px] -mr-[5px] pb-[8px] xl:pb-2.5" />

                <h3 
                    className="text-[19px] xl:text-[21px] font-semibold leading-[1.26667] tracking-[.009em] text-app-text-dark-9"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>

            {/* Specs */}
            <ul className="mt-5 space-y-[.4em] flex flex-col">
                {specs.map(spec => (
                    <li key={spec} className="text-[14px] leading-[1.42859] -tracking-[.016em]">{spec}</li>
                ))}
            </ul>

            {/* Price Container */}
            <div className="mt-[.7em] xl:mt-[.8em]">
                <h1 className="text-[19px] xl:text-2xl leading-[1.16667] font-semibold tracking-[.009em]">{prices.main}</h1>
                <span className="inline-block mt-4 xl:mt-5 text-[13px] py-[5px] xl:text-[14px] leading-[1.42859] -tracking-[.016em] xl:py-[7px]">או</span>
                <div>
                    <h1 className="text-[19px] xl:text-2xl leading-[1.16667] font-semibold tracking-[.009em]">{prices.alternate}</h1>
                    <span className="text-[13px] py-[5px] xl:text-[14px] leading-[1.42859] -tracking-[.016em] xl:py-[7px]">{prices.content}</span>
                </div>
            </div>

            {/* Buy Button */}
            <ProductDescriptionBannerLinkContainer 
                links={links}
                className="mt-5"
            />
        </blockquote>
    )
}

export default SelectionProductItem