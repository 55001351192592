import React, { useCallback, useEffect, useState } from 'react';
import UserImage from "../assets/svg/user.svg";
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { useRecoilValue } from 'recoil';
import { user } from '../recoil';
import { useFormik } from 'formik';
import { makeRequest } from '../lib/utils';
import { Loader } from '../lib/constants';


const Orders = () => {

	const [orders, setOrders] = useState();
	const [loading, setLoading] = useState(false);

	const getOrders = useCallback(async () => {
		setLoading(true);
		const res = await makeRequest("order", "GET");

		if (res.status < 300) {
			const data = await res.json();
			console.log(data);
			setOrders(data);
		}

		setLoading(false);
	}, []);

	useEffect(() => {
		getOrders();
	}, [])

	return (
		<div className='p-4 flex justify-center'>
			{
				loading ? (
					<img src={Loader} alt="loading" />
				) : orders ?
					(
						<>
							{
								orders.length === 0 ? (
									<p>אין לך הזמנות</p>
								) :
									(
										<div className='max-h-[350px] bg-gray-100 overflow-y-scroll text-sm py-2 rounded direction-rtl'>
											{
												orders.map((order) => (
													<div className='grid grid-cols-2 border-b py-2 border-gray-400 hover:bg-gray-50 px-2'>
														<span>כמות</span>
														<span>{order.amountDue}</span>
														<span>כתובת ארנק</span>
														<span>{order.walletAddress}</span>
														<span>סטָטוּס</span>
														<span>{order.status}</span>
													</div>
												))
											}
										</div>
									)
							}

						</>
					) : (
						<p>שירותים לא זמינים</p>
					)
			}
		</div>
	)
}


const AccountForm = ({ getFormToDisplay }) => {

	const [activeTab, setActiveTab] = useState("details");
	const currentUser = useRecoilValue(user);

	const formik = useFormik({
		initialValues: {
			...currentUser
		}
	})
	return (
		<div className='w-full'>
			<header className='px-3 pt-3 flex flex-col gap-y-3 bg-grey-400 border-app-border'>
				{/* Back Button */}
				<Button
					type="button"
					className="text-sm md:p-0 lg:p-0"
					onClick={() => getFormToDisplay("disconnect")}
				>
					התנתקות
				</Button>

				{/* Header Content */}
				<div className='flex flex-col items-center justify-center gap-y-1.5'>
					<img
						src={UserImage}
						alt="user auth"
						loading="lazy"
						className='h-16'
					/>
					{
						activeTab === "orders" && <h6 className='text-sm text-app-text uppercase'>{`${currentUser.firstName} ${currentUser.lastName}`}</h6>
					}
				</div>
				<ul className="flex justify-center">
					<li className="">
						<span aria-current="page"
							className={`inline-block text-gray-500 py-4 px-4 text-sm font-medium text-center cursor-pointer ${activeTab === "orders" ? "bg-white border-t border-l border-r" : ""}`} onClick={() => setActiveTab("orders")}>הזמנות שלי</span>
					</li>
					<li className="mr-2">
						<span className={`inline-block text-gray-500 py-4 px-4 text-sm font-medium text-center cursor-pointer ${activeTab === "details" ? "bg-white border-t border-l border-r" : ""}`} onClick={() => setActiveTab("details")}>פרטים אישים</span>
					</li>

				</ul>
			</header>


			{/* Form */}
			{
				activeTab === "details" && <form className='px-4 py-6 flex flex-col gap-y-3.5'>
					<Input
						type="name"
						name="firstName"
						placeholder="*שם פרטי"
						value={formik.values.firstName}
						onChange={formik.handleChange}
						className="direction-rtl"
					/>

					<Input
						type="name"
						name="lastName"
						placeholder="*שם משפחה"
						value={formik.values.lastName}
						onChange={formik.handleChange}
						className="direction-rtl"
					/>
					<Input
						type="email"
						name="email"
						placeholder="*אימייל"
						value={formik.values.email}
						onChange={formik.handleChange}
						className="direction-rtl"
					/>

					<div className='flex items-center justify-start'>
						<Input
							type="checkbox" id="changePassword" className="w-fit bg-black" />
						<label htmlFor="changePassword">שינוי סיסמה</label>
					</div>

					<Button
						variant="primary"
						title="form submit button"
						className="rounded-full py-2"
						width="full"
						type="button"
					>
						שמור
					</Button>
				</form>
			}

			{
				activeTab === "orders" && <div>
					<Orders />
				</div>
			}
		</div>
	)
}

export default AccountForm