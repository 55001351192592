import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack';
import { makeRequest } from '../../utils';
import { useState } from 'react';
import Loader from '../Loader';


export default function DeleteDialog({ handleClose, title, id }) {
	const [loading, setLoading] = useState(false);

	const handleConfirm = async () => {
		setLoading(true);
		const res = await makeRequest(`${title}/${title === "order" ? id.orderID : id}`, "DELETE")


		enqueueSnackbar({
			message: res?.status < 300 ? `${title} deleted` : 'An error occurred', variant: res?.status < 300 ? "success" : "error",
			autoHideDuration: 3000
		})
		setLoading(false);
		handleClose(res?.status < 300 ? id : false)
	}

	return (
		<>
			<Dialog open={true}>
				<DialogTitle>{`Delete ${title}`}</DialogTitle>
				<DialogContent>
					{
						loading ? (
							<Loader />
						) :
							(
								<>
									<DialogContentText>
										This action is unrecoverable.
									</DialogContentText>
									<DialogContentText>
										Click confirm to continue
									</DialogContentText>
								</>
							)
					}

				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleConfirm} variant='success'>Confirm</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
