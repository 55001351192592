import React, { useState } from 'react'
import { motion } from 'framer-motion';
import Icons from '../Icons'
import { Input } from '../ui/Input';
import HeaderImage from "../../assets/images/subscribe_modal_header.webp";
import Banner from "../../assets/images/subscribe_modal_banner.webp";
import { Button } from '../ui/Button';

const modalVariant = {
    hidden: {
        y: -70,
        opacity: 0
    },
    show: {
        y: 0,
        opacity: 1,
        transition: {
            delay: 1,
            duration: .15
        }
    }
}

const SubscribeModal = ({ closeModal }) => {
    const [subscribeForm, setSubscribeForm] = useState({
        email: "",
        firstName: ""
    });

    const handleChange = ({ target }) => {
        const { name, value } = target;

        setSubscribeForm({
            ...subscribeForm,
            [name]: value
        })
    } 

    return (
        <motion.div 
            variants={modalVariant}
            initial="hidden"
            animate="show"
            exit="hidden"
            className="rounded-[6px] w-[650px] h-[500px] bg-[rgb(251,251,251)] origin-[center_center_0px] relative direction-rtl"
        >
            <span 
                onClick={closeModal}
                className="absolute top-1.5 left-[617px] cursor-pointer"
            >
                <Icons.Close strokeWidth={2} className="w-[30div
    divpx] h-[30px]" />
            </span>
            <figure className="w-[518px] h-[195px] mx-auto mt-5">
                <picture>
                    <source 
                        srcSet={HeaderImage}
                        type="image/webp"
                        className="w-full h-full object-cover"
                    />
                    <img 
                        src={HeaderImage}
                        className="w-full h-full object-cover"
                    />
                </picture>
            </figure>

            {/* Form Container */}
            <form className="w-[500px] mx-auto grid grid-cols-[repeat(2,1fr)_max-content] direction-ltr mt-3 pr-2">
                <Input 
                    type="email"
                    name="email"
                    placeholder="דוא״ל"
                    aria-label="דוא״ל"
                    role="textbox"
                    className="h-[45px] text-base text-[rgb(62,62,62)]bg-white border border-[rgb(18,60,86)] text-right placeholder:text-black placeholder:font-normal focus:border-[rgb(18,60,86)]"
                    value={subscribeForm.email}
                    onChange={handleChange}
                />

                <Input 
                    type="text"
                    name="firstName"
                    placeholder="שם פרטי"
                    aria-label="שם פרטי"
                    role="textbox"
                    className="h-[45px] text-base text-[rgb(62,62,62)]bg-white border border-[rgb(18,60,86)] text-right placeholder:text-black placeholder:font-normal focus:border-[rgb(18,60,86)]"
                    value={subscribeForm.firstName}
                    onChange={handleChange}
                />

                <Button 
                    variant="primary"
                    className="h-[45px] md:text-base font-bold py-0 px-12 rounded-none"
                    width="full"
                >
                    Yes Please
                </Button>
            </form>

            {/* Banner */}
            <div className="h-[400px] mx-auto absolute top-[110px] left-[116px]">
                <picture>
                    <source 
                        srcSet={Banner}
                        type="image/webp"
                        className="w-full h-full object-cover"
                    />
                    <img 
                        src={Banner}
                        className="w-full h-full object-cover"
                    />
                </picture>
            </div>
        </motion.div>
    )
}

export default SubscribeModal