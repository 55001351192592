import React from 'react'
import SelectionProductItem from './SelectionProductItem'

const SelectionProductsCollection = ({ products }) => {
  return (
    <div className="flex flex-wrap gap-y-[30px] gap-x-[16.6666%] xl:gap-y-[30px] xl:gap-x-[70px]">
        {products.map((product, index) => (
            <SelectionProductItem 
                key={product.title}
                index={index+1}
                productLength={products.length}
                {...product}
            />
        ))}
    </div>
  )
}

export default SelectionProductsCollection