import { atom, atomFamily } from "recoil";

export const allProductsFamily = atomFamily({
	key: "ALL_PRODUCTS_FAMILY",
	default: null,
});


export const singleCartItemModalOpen = atom({
	key: "SINGLE_CART_ITEM_OPEN",
	default: false,
});

export const orderMade = atom({
	key: "ORDER_MADE",
	default: false
});

export const user = atom({
	key: "CURRENT_SIGNED_USER",
	default: null
});