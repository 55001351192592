import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { BASE_URL } from "../admin/constants";
import * as yup from 'yup';
import { USER_KEY } from "./constants";

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}

export function getCurrentParams(paramsTitle) {
    const currentPath = window.location.href;
    const urlParams = new URLSearchParams(`?${paramsTitle}=${currentPath}`);
    const getURL = urlParams.get(paramsTitle);
    const lastParams = getURL.slice(getURL.lastIndexOf("/"), getURL.length);

    return lastParams;
}


export const makeRequest = async (path, method, body) => {
    const token = sessionStorage.getItem(USER_KEY);
    const fullUrl = BASE_URL + path;
    const payload = {
        method,
        headers: {
            Authorization: `Bearer ${token}`
        },
    };

    if (body) {
        payload.headers["Content-Type"] = "application/json";
        payload.body = JSON.stringify(body);
    }

    const res = await fetch(fullUrl, payload).catch(err => ({ status: 500 }));

    return res;

}

// Function to create dynamic Yup schema
export const createValidationSchema = (rules) => {
    // Initialize Yup object
    let schema = yup.object();

    // Loop through rules and add validation methods dynamically
    rules.forEach((rule) => {
        const { key, type, required, validation } = rule;

        // Add type-specific validation
        if (type === 'string') {
            schema = schema.shape({ [key]: yup.string() });
        } else if (type === 'number') {
            schema = schema.shape({ [key]: yup.number() });
        } else if (type === 'boolean') {
            schema = schema.shape({ [key]: yup.boolean() });
        }

        // Add required validation if specified
        if (required) {
            schema = schema.required("Field is required");
        }

        // Add custom validation if specified
        if (validation) {
            schema = schema.test({
                name: key,
                message: validation.message,
                test: validation.test,
            });
        }
    });

    return schema;
};
