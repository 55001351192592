import React from 'react'

const BreadCrumb = () => {
  return (
    <blockquote className="m-2 px-4 py-2 direction-rtl text-xs text-grey-400">
        BreadCrumb
    </blockquote>
  )
}

export default BreadCrumb