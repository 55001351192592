import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack';
import * as yup from "yup";
import { useFormik } from 'formik';
import { makeRequest } from '../../utils';

const validationSchema = yup.object({
	title: yup.string().required("title is required"),
	content: yup.string().required("content is required"),
})

export default function EditReviewDialog({ productId, productType, review, handleClose }) {

	const formik = useFormik({
		validationSchema,
		initialValues: {
			title: review.title,
			content: review.content,
		},

		onSubmit: async (values) => {

			const res = await makeRequest(`productreview/${review.id}`, "PUT", { ...values, productId, productType })

			enqueueSnackbar({
				message: res?.status < 300 ? "Review updated" : "An error occurred", variant: res?.status < 300 ? "success" : "error", autoHideDuration: 2000
			})
			handleClose(res?.status < 300 ? values : false)
		}
	})


	return (
		<>
			<Dialog open={true}>
				<DialogTitle>Update Review</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="title"
						label="Title"
						name='title'
						fullWidth
						variant="standard"
						onChange={formik.handleChange}
						value={formik.values.title}
						error={formik.touched.title && Boolean(formik.errors.title)}
						helperText={formik.touched.title && formik.errors.title}
					/>
					<TextField
						margin="dense"
						id="content"
						name='content'
						label="Content"
						fullWidth
						variant="standard"
						onChange={formik.handleChange}
						value={formik.values.content}
						error={formik.touched.content && Boolean(formik.errors.content)}
						helperText={formik.touched.content && formik.errors.content}
					/>

				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose()} disabled={formik.isSubmitting}>Cancel</Button>
					<Button onClick={formik.handleSubmit} disabled={formik.isSubmitting}>Confirm</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
