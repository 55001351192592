import React from 'react';
import { Button } from './ui/Button';
import Tooltip from './ui/Tooltip';
import Icons from './Icons';
import LocationPin from "../assets/images/location-pin.png";
import { useStickyBox } from 'react-sticky-box';


const CartActionTrayMobile = ({ product, onAddToCartClicked, visible, newPrice }) => {
	const stickyRef = useStickyBox({
		offsetTop: 50,
	})
	return (
		<div ref={stickyRef} className={`w-full h-fit min-h-[128px] z-50 md:hidden border border-[#d6d6d5] bg-grey-370 py-4`}>
			<div className='border-b'>
				<div className='grid grid-cols-2 gap-6 px-6'>
					<h2 className='col-span-2 text-center text-lg text-primary-300'>{product.name}</h2>
					<Button
						variant="primary"
						size="none"
						className="px-4 h-fit w-fit py-1 rounded-full"
						onClick={onAddToCartClicked}
						type="submit"
					>
						הוספה לסל
					</Button>

					{/* Price Box */}
					<div className='text-end'>
						<h1 className="text-xl text-primary-500">₪ {newPrice ? newPrice.toLocaleString("en-US") : product?.price?.toLocaleString("en-US")}</h1>

						{/* Tooltip */}
						<Tooltip title="אפשרויות תשלום">
							<p>אפשרות עד 36 תשלומים ועד 12 תשלומים ללא ריבית.</p>
						</Tooltip>
					</div>
				</div>
			</div>

			<div>

			</div><div className='grid grid-cols-[100px_1fr] pt-4 px-6'>
				<blockquote className='grid grid-cols-[1fr_max-content] gap-x-2'>
					{/* Content */}
					<div className='text-end flex flex-col items-end'>
						<h6 className='font-bold text-[.81rem]'>:איסוף</h6>
						<p className='flex items-center font-light gap-x-0.5 text-[.81rem] underline text-primary-500'>
							בדיקת מלאי בסניפים
						</p>
					</div>

					<img
						src={LocationPin}
						className='w-[21px] h-5'
						alt=''
					/>
				</blockquote>

				<blockquote className='grid grid-cols-[1fr_max-content] gap-x-2.5'>
					{/* Content */}
					<div className='text-end flex flex-col items-end'>
						<h6 className='font-bold text-[.81rem]'>:קבל עזרה ברכישה</h6>
						<p className='flex items-center font-light gap-x-0.5 text-[.81rem]'>לזמן האספקה המדוייק יש לבחור דגם</p>
						<p className='flex items-center font-light gap-x-0.5 text-[.81rem] text-accent-600'>משלוח חינם בהזמנה מעל 199.90 ₪</p>
					</div>

					<Icons.Package className='w-[21px] h-5' />
				</blockquote>

				<div>

				</div>
			</div>
		</div>
	)
}

export default CartActionTrayMobile;