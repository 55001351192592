import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack';
import * as yup from "yup";
import { useFormik } from 'formik';
import { makeRequest } from '../../utils';

const validationSchema = yup.object({
	name: yup.string().required("name is required"),
	hex: yup.string().required("hex is required").max(7, "Hex cannot be longer than 7 characters"),
})

export default function AddColorDialog({ handleClose }) {

	const formik = useFormik({
		validationSchema,
		initialValues: {
			name: '',
			hex: '',
		},

		onSubmit: async (values) => {

			const res = await makeRequest("color", "POST", values)

			enqueueSnackbar({
				message: res?.status < 300 ? "Color added" : "An error occurred", variant: res?.status < 300 ? "success" : "error", autoHideDuration: 2000
			})
			handleClose(res?.status < 300 ? values : false)
		}
	})


	return (
		<>
			<Dialog open={true}>
				<DialogTitle>New Color</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Name"
						name='name'
						fullWidth
						variant="standard"
						onChange={formik.handleChange}
						value={formik.values.name}
						error={formik.touched.name && Boolean(formik.errors.name)}
						helperText={formik.touched.name && formik.errors.name}
					/>
					<TextField
						margin="dense"
						id="hex"
						name='hex'
						label="Hex"
						fullWidth
						variant="standard"
						onChange={formik.handleChange}
						value={formik.values.hex}
						error={formik.touched.hex && Boolean(formik.errors.hex)}
						helperText={formik.touched.hex && formik.errors.hex}
					/>
					<div className="w-6 h-6 rounded-full" style={{ backgroundColor: formik.values.hex }}>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose()} disabled={formik.isSubmitting}>Cancel</Button>
					<Button onClick={formik.handleSubmit} disabled={formik.isSubmitting}>Confirm</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
