import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ColorPage from "./ColorPage";
import BranchPage from "./BranchPage";
import DeleteDialog from "../components/dialogs/DeleteDialog";
import Loader from "../components/Loader";
import { enqueueSnackbar } from "notistack";
import { makeRequest } from "../utils";
import OrderPage from "./OrderPage";
import StoragePage from "./StoragePage";

const columns = [
	{ id: 'id', label: 'Product ID', minWidth: 80 },
	{ id: 'name', label: 'Name', minWidth: 120 },
	{ id: 'price', label: 'Price', minWidth: 120, format: (value) => `₪ ${value.toLocaleString('en-US')}` },
	{ id: 'actions', label: 'Actions' }
]


const productRoutes = ["accessory", "ipad", "phone", "mac", "watch"];

const Product = () => {
	const { product } = useParams();
	const [rows, setRows] = useState([

	]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [dialog, setDialog] = useState(false); //{id} when set
	const [fetching, setFetching] = useState(true);
	const navigate = useNavigate();

	const handleViewClicked = (p) => {
		navigate(`detail/${p.id}`);
	}

	const handleDeleteDialogClosed = (id) => {
		if (id) {
			setRows((cur) => cur.filter(c => c.id !== id))
		}

		setDialog(false);
	}

	const fetchProducts = useCallback(async () => {
		setFetching(true)

		const response = await makeRequest(product, "GET");

		if (response?.status === 200) {
			const products = await response.json();
			setRows(products);
		} else {
			enqueueSnackbar({
				message: `Error fetching ${product}`,
				variant: 'error', autoHideDuration: 3000
			})
		}

		setFetching(false);
	}, [product])

	useEffect(() => {
		if (!productRoutes.includes(product)) {
			return
		}
		fetchProducts();
	}, [product])

	if (product === "color") {
		return <ColorPage />
	}

	if (product === "branch") {
		return <BranchPage />
	}

	if (product === "order") {
		return <OrderPage />
	}

	if (product === "storage") {
		return <StoragePage />
	}

	return (
		<>
			<div className="flex justify-between">
				<h2 className="text-4xl font-bold mb-10 uppercase">{product}</h2>
				<Link to="add">
					<Button variant="contained" sx={{ backgroundColor: "#014868" }}>Add new <span className="uppercase ml-2"> {product}</span></Button></Link>
			</div>

			{
				fetching ? (
					<Loader />
				)
					:
					(<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer sx={{ maxHeight: 440 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth }}
												sx={{ fontWeight: 700 }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
													{columns.map((column) => {
														const value = row[column.id];
														return column.id === "actions" ?
															(<TableCell key={column.id}>
																<div className='flex items-center mt-2 gap-3'><Button variant='contained' color='primary' onClick={() => handleViewClicked(row)} size="small">View</Button>
																	<Button variant='contained' color='error' onClick={() => setDialog({ id: row.id })}
																		size="small">Delete</Button></div>
															</TableCell>)
															: (
																<TableCell key={column.id} align={column.align}>
																	{column.format ? column.format(value)
																		: value}
																</TableCell>
															);
													})}

												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[25, 50, 100]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							labelRowsPerPage="Products per page:"
							onRowsPerPageChange={(evt) => setRowsPerPage(evt.target.value)}
							onPageChange={(evt, page) => setPage(page)}

						/>
					</Paper>)}

			{
				dialog && <DeleteDialog handleClose={handleDeleteDialogClosed} title={product} id={dialog.id} />
			}
		</>
	)
}

export default Product;