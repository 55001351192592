import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack';
import { makeRequest } from '../../utils';
import { useState } from 'react';



export default function RemoveProductColor({ productId, productType, colorId, handleClose }) {
	const [loading, setLoading] = useState(false)

	const handleConfirm = async () => {
		setLoading(true);
		const res = await makeRequest("productcolor", "DELETE", {
			productId, productType, colorId
		});

		if (res.status < 300) {
			enqueueSnackbar({
				message: "Color removed from product", variant: "success", autoHideDuration: 3000
			});
			handleClose(colorId);
			return
		} else {
			enqueueSnackbar({
				message: "An error occurred", variant: "error"
			})
		}
		handleClose()
	}

	return (
		<>
			<Dialog open={true}>
				<DialogTitle>Remove Color</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This action is unrecoverable.
					</DialogContentText>
					<DialogContentText>
						Click confirm to continue
					</DialogContentText>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} disabled={loading}>Cancel</Button>
					<Button onClick={handleConfirm} variant='success' disabled={loading}>Confirm</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
