import React from 'react'
import ChapterItem from './ChapterItem';
import { cn } from '../lib/utils';

const ChapterItemCollection = ({ chapters, chaptersClassName, chaptersSubClassName }) => {
  return (
    <div className={cn("z-30 overflow-x-scroll w-11/12 mx-auto sm:mx-0 sm:w-full sm:pb-0 sm:px-0 sm:overflow-hidden bg-grey-100", chaptersClassName)}>
      <div className={cn("flex justify-center gap-x-0.5 w-max mx-auto px-3 mt-3 mb-[30px]", chaptersSubClassName)}>
        {chapters.map(chapter => (
          <ChapterItem 
            key={chapter.title}
            {...chapter}
          />
        ))}
      </div>
    </div>
  )
}

export default ChapterItemCollection;