import Box from '@mui/material/Box';
import { Loader as Loading } from '../../lib/constants';

export default function Loader() {

	return (
		<div className="flex justify-center items-center h-3/4">
			<Box sx={{ display: 'flex' }}>
				<img src={Loading} alt="" />
			</Box>
		</div>
	)
}