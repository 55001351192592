import React from 'react';
import { Link } from 'react-router-dom';
import { navigationLinks } from '../lib/constants';
import Portals from './Portals';
import SearchBox from "./SearchBox";
import UserFormIcon from './UserFormIcon';
import NavigationLink from './navigation/NavigationLink';


const Offset = ({ type, closeOffset }) => {
  return (
    <Portals elementID="offset-root">
        <div className="grid grid-rows-[max-content_1fr] border-t border-app-border-dark fixed top-[3.26rem] md:top-[3.6rem] md-lg:top-16 left-0 bg-white w-full h-full z-[70] pb-4 xl:hidden">
            {/* Header */}
            <header>
                {/* Search Box */}
                  <SearchBox />
            </header>       

            {/* Links */}
            <nav className='overflow-y-scroll pt-3 sm:pt-5 flex justify-center'>
                <ul className='flex flex-col text-center gap-y-3 md:gap-y-3.5'>
                    {navigationLinks.reverse().map(link => (
                        <NavigationLink 
                            key={link.title}
                            {...link}
                            closeOffset={closeOffset}
                            type={type}
                        />
                    ))}
                </ul>
            </nav>
        </div>
    </Portals>
  )
}

export default Offset