import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Select } from './ui/Input';
import ProductFilterButton from './ProductFilterButton';
import ProductFilterBox from './ProductFilterBox';
import ProductsCollectionOverview from './ProductsCollectionOverview';
import MobileProductFIlterBox from './MobileProductFIlterBox';
import { cn, makeRequest } from '../lib/utils';
import { useRecoilState } from 'recoil';
import { allMac } from '../recoil/mac/state';
import Loader from '../admin/components/Loader';
import { allWatches } from '../recoil/watch/state';
import slugify from "@sindresorhus/slugify";
import { useAddProducts } from '../hooks/useAddProducts';


const macHeaders = {
    "studio-display": "Studio Display",
    "imac": "iMac",
    "mini": "Mac Mini",
    "studio": "Mac Studio",
    "macpro": "Mac Pro",
    "macbook-air": "MacBook Air",
    "macbook-pro": "MacBook Pro",
}

const watchHeaders = {
    "ultra": "Apple Watch Ultra",
    "ultra-2": "Apple Watch Ultra 2", //only
    "series-9": "Apple Watch Series 9",
    "series-8": "Apple Watch",
    "series-7": "Apple Watch",
    "series-6": "Apple Watch",
    "watch-se": "Apple Watch",
}

const ProductCollectionPreview = ({ showHeader, heading, showFilter = true, showSortSelect = true, showColor = true, products = [] }) => {
    const [productSortValue, setProductSortValue] = useState("");
    const [isFilterBoxOpen, toggleFilterBoxOpen] = useState(true);

    // const [searchParams, setSearchParams] = useSearchParams();
    // const testing = searchParams.get("testing");

    // useEffect(() => {
    //     setSearchParams({ testing: "random" })
    // }, []);
    
    return (        
        <div className="wrapper md:p-2">
            {!showHeader && (
                <header className={cn('pt-4 text-center direction-rtl', heading?.headerClassName ?? "")}>
                    {heading ? (
                        <>
                            {/* Product Title */}
                            <h1 className={cn('text-[1.8rem] font-light md:text-[2rem]', heading?.titleClassName ?? "")}>{heading.title}</h1>
                            {heading?.subTitle && (
                                <span className='text-md font-light'>{heading?.subTitle}</span>
                            )}
                        </>
                    ) : (
                        <>
                            <h1 className='text-[1.8rem] md:text-[2rem] font-light'>בחרו את ה-Apple Watch שלכם!</h1>
                            <span className='text-md font-light'>SE</span>
                        </>
                    )}
                </header>
            )}

            {/* Product Filter Box */}
            {showFilter && (
                <MobileProductFIlterBox
                    data={{
                        "סוג מוצר": ["AirTag", "Apple TV", "Product Red"],
                        "יצרן": ["12 twelve south", "ANKER", "ANKR"]
                    }}
                />
            )}

            {/* Actions */}
            {(showSortSelect && showFilter) && (
                <div className='flex items-center justify-center md:justify-between mt-8 md:mt-1 direction-rtl'>
                    {/* Product Filter Button */}
                    {showFilter && (
                        <ProductFilterButton toggleFilterBoxOpen={() => toggleFilterBoxOpen(props => !props)} />
                    )}

                    {/* Product Sort */}
                    {showSortSelect && (
                        <Select 
                            title="מיין לפי:"
                            label=" מיין לפי:"
                            className="text-md pr-3 direction-rtl"
                            options={[
                                {
                                    key: "Recommended",
                                    value: "position"
                                },
                                {
                                    key: "Newest",
                                    value: "open_date"
                                },
                                {
                                    key: "Delivery times",
                                    value: "shipping_date"
                                },
                                {
                                    key: "Price: low to high",
                                    value: "low_to_high_price"
                                },
                                {
                                    key: "Price: high to low",
                                    value: "high_to_low_price"
                                },
                            ]}
                            value={productSortValue}
                            onChange={({ target }) => setProductSortValue(target.value)}
                        />
                    )}
                </div>
            )}

            {/* Products */}
            <main className={`grid grid-cols-1 md:grid-cols-[1fr_max-content] gap-x-4 ${(!showFilter && !showSortSelect) ? "mt-6 lg:mt-8" : "mt-6 sm:mt-4 md:mt-2"}`}>
                {/* Products Collection Overview */}
                <ProductsCollectionOverview products={products} showColor={showColor} />

                {/* Products Filter Box */}
                {(isFilterBoxOpen && showFilter) && (
                    <ProductFilterBox 
                        isOpen={isFilterBoxOpen}
                        data={{
                            "סוג מוצר": ["AirTag", "Apple TV", "Product Red"],
                            "יצרן": ["12 twelve south", "ANKER", "ANKR"]
                        }}
                    />
                )}
            </main>
        </div>
    )
}


export const ProductCollectionPreviewRoot = () => {
    const { productCategory, productSubCategory } = useParams();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [macs, setMacs] = useRecoilState(allMac);
    const [watches, setWatches] = useRecoilState(allWatches);
    const [previewOptions, setPreviewOptions] = useState({});
    const addProducts = useAddProducts()

    const fetchMacs = useCallback(async () => {
        setLoading(true);

        const res = await makeRequest("mac");

        if (res.status < 300) {
            let macs = await res.json();
            macs = macs.map((m) => ({ ...m, slug: slugify(m.name) }));
            setMacs(macs);
            addProducts(macs);
        }
    }, []);


    const fetchWatches = useCallback(async () => {
        setLoading(true);

        const res = await makeRequest("watch");

        if (res.status < 300) {
            let watches = await res.json();
            watches = watches.map((w) => ({ ...w, slug: slugify(w.name) }));
            setWatches(watches);
            addProducts(watches);
        }
    }, [])

    useEffect(() => {

        if (productCategory === "mac") {
            if (!macs) {
                fetchMacs();
                return;
            }
            setPreviewOptions({
                showFilter: false,
                heading: {
                    title: productSubCategory === "studio-display" ? "" : `בחרו את ה-${macHeaders[productSubCategory]} שלכם`
                }
            })
            const products = macs.filter((m) => m.name.toLowerCase().includes(macHeaders[productSubCategory].toLowerCase()));
            setProducts(products);
        }
        else if (productCategory.toLowerCase() === "watch") {
            if (!watches) {
                fetchWatches();
                return;
            }

            setPreviewOptions({
                heading: {
                    title: productSubCategory === "ultra-2" ? watchHeaders[productSubCategory] : `בחרו את ה-${watchHeaders[productSubCategory]} שלכם`
                },
                showColor: false,
            });
            const pattern = new RegExp(`\\b${productSubCategory.replace("-", " ").toLowerCase()}\\b`, "i");
            let _products = watches.filter((w) => pattern.test(w.name.toLowerCase()));

            if (productSubCategory === "ultra") {
                _products = _products.filter((w) => !w.name.toLowerCase().includes("ultra 2"))
            }

            setProducts(_products);
        }

        setLoading(false);

    }, [macs, watches])

    return loading ? (
        <div className='py-10'>
            <Loader />
        </div>
    ) : (
        <ProductCollectionPreview products={products} {...previewOptions} />
    )
}

export default ProductCollectionPreview