import { useCallback, useEffect, useState } from "react";
import { makeRequest } from "../../utils";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { enqueueSnackbar } from "notistack";
import Loader from "../Loader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";


export default function ProductBranchDialog({ productId, productType, handleClose }) {

	const [branches, setBranches] = useState([]);
	const [fetching, setFetching] = useState(true);
	const [setting, setSetting] = useState(false);
	const [selectedBranch, setSelectedBranch] = useState(-1)

	const getBranches = useCallback(async () => {
		const res = await makeRequest("branch", "GET");

		if (res.status < 300) {
			setBranches(await res.json());
		}

		setFetching(false)
	}, [])

	const handleBranchSelected = async () => {
		if (selectedBranch === -1) {
			return;
		}
		setSetting(true)

		const res = await makeRequest("productbranch", "POST", {
			productId,
			productType,
			branchId: selectedBranch
		});

		if (res.status < 300) {
			enqueueSnackbar({
				message: "Product added to branch", variant: "success", autoHideDuration: 3000
			});
			handleClose(branches.filter(c => c.id === selectedBranch)[0]);
			return;
		}
		else if (res.status === 400) {
			enqueueSnackbar({
				message: "This product already exist in the selected branch", variant: "error", autoHideDuration: 3000
			})
		}
		else {
			enqueueSnackbar({
				message: "An error occurred", variant: "error"
			})
		}
		setSetting(false)
		handleClose();
	}

	useEffect(() => {
		getBranches()
	}, [])


	return (
		<>
			<Dialog open={true}>
				<DialogTitle>Add Product to Branch</DialogTitle>
				<DialogContent>
					<div className="w-[90vw] md:w-[30vw]">
						{
							fetching ? (<Loader />)
								:
								(
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={selectedBranch}
										onChange={(evt) => setSelectedBranch(evt.target.value)}
										fullWidth
										name="category"
										sx={{ marginLeft: 1 }}

									>
										<MenuItem value={-1} disabled>Select a branch</MenuItem>

										{
											branches.map((c) => <MenuItem key={c.id} value={c.id}>
												<div className="flex flex-col">
													<span className="font-semibold">{
														c.title
													}</span>
													<span className="text-xs">{c.address}</span>
												</div>
											</MenuItem>)
										}
									</Select>

								)
						}
					</div>

				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose()} disabled={setting}>Cancel</Button>
					<Button onClick={handleBranchSelected} disabled={setting}>Confirm</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}