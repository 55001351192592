import { ADMIN_KEY, BASE_URL } from "./constants";


export const getFullUrl = (path) => `${BASE_URL}admin/${path}`;

export const capitalize = (str) => {
	const capitalizedWords = [];

	str.split(" ").forEach(s => capitalizedWords.push(s[0].toUpperCase() + s.slice(1)));

	return capitalizedWords.join(" ");
}


export const makeRequest = async (path, method, body) => {
	const token = sessionStorage.getItem(ADMIN_KEY);

	const payload = {
		method,
		headers: {
			Authorization: `Bearer ${token}`
		},
	};

	if (body) {
		payload.headers["Content-Type"] = "application/json";
		payload.body = JSON.stringify(body);
	}

	const res = await fetch(getFullUrl(path), payload).catch(err => ({ status: 500 }));

	if (res.status === 401) {
		sessionStorage.removeItem(ADMIN_KEY);
		window.location.replace("/admin/signin");
	} else {
		return res;
	}
}