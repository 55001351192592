import React from 'react';
import { cn } from '../lib/utils';

const ProductSwatchAttributeItem = ({ option, selectedSwatchAttribute, setSelectedSwatchAttribute, optionTitle, title }) => {
    const swatchTitle = option?.title ?? title ?? option;
    const swatchTitleStyle = `${selectedSwatchAttribute === option ? "text-black" : "text-[#bbb]"} font-light capitalize text-base transition-colors duration-200`;
    const swatchSubTitle = `${selectedSwatchAttribute === option ? "font-semibold" : ""}`

    return (
        <div 
            title={swatchTitle}
            name={swatchTitle}
            className={`flex select-none relative items-center justify-end border ${selectedSwatchAttribute === swatchTitle || selectedSwatchAttribute === option?.color || selectedSwatchAttribute === option ? "border-black" : "border-app-border hover:border-black"} transition-colors duration-200 py-8 px-[1.3rem] h-24 rounded-[.2em] cursor-pointer`}
            onClick={() => setSelectedSwatchAttribute(typeof option === "string" ? swatchTitle : title ? option : option.color)}
        >
            {typeof option === "string" || typeof option === "number" ? (
                <div className={`${swatchTitleStyle} flex flex-col justify-center items-center w-full`}>
                    <span >
                        {title ?? option}
                    </span> 
                    <span className={`text-primary-550 text-sm ${swatchSubTitle}`}>{optionTitle}</span>
                </div>
            ) : (
                    <div className="flex items-center justify-between w-[75%]">
                        <span className={cn(swatchTitleStyle, "text-[1.05rem] text-end")}>{option.title}</span>
                        <span style={{ background: option.color }} className="w-[1.5rem] h-[1.5rem] rounded-full absolute top-2 right-2" />
                </div>
            )}
        </div>
    )
}

export default ProductSwatchAttributeItem